import { PrimaryInput } from '../../../Shared/Ui/UIKit/input';
import { PrimaryBtn, modsType } from '../../../Shared/Ui/UIKit/button';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import {
  switchBeetwenComponent,
  SideBarScreen,
  openSideBar,
  closeSideBar, setBasketReturn
} from '../../../Shared/Redux/features/sideBarSlice';
import './index.scss';
import { login } from '../../../Shared/Api/auth';
import { useState } from 'react';
import { useSignIn, useSignOut } from 'react-auth-kit';
import { useIsAuthenticated } from 'react-auth-kit';
import { ModalsName, onOpen } from '../../../Shared/Redux/features/modalSlice';

export const Auth = () => {
  const { basket } = useAppSelector((state) => state.basket);
  const isBasketReturn = useAppSelector((state) => state.sideBar.isBasketReturn);

  const isAuthenticated = useIsAuthenticated();
  console.log('isAuth: ', isAuthenticated());
  const signIn = useSignIn();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const dispatch = useAppDispatch();
  const registrationPage = () => {
    dispatch(switchBeetwenComponent(SideBarScreen.REGISTRATION));
  };
  const personalArea = () => {
    dispatch(switchBeetwenComponent(SideBarScreen.PERSONALAREA));
  };

  const callLogin = async () => {
    // event.preventDefault();
    const res = await login({
      email,
      password,
      basket,
    })
    if (!res) return;
    localStorage.removeItem('cart');
    if (
        res.data && res.data.token &&
      signIn({
        token: res.data.token,
        expiresIn: 9999999999,
        tokenType: 'Bearer',
        authState: res.data.user,
      })
    ) {
      setError(false);
      console.log('auth success');
      if (isBasketReturn) {
        dispatch(setBasketReturn(false));
        if (window.innerWidth <= 600) { // isMobile
          document.scrollingElement.scrollTop = 0;
          dispatch(switchBeetwenComponent(SideBarScreen.CONTACTS));
        } else {
          dispatch(closeSideBar());
          dispatch(onOpen(ModalsName.ORDERMODAL));
        }
      } else {
        personalArea();
      }
    } else {
      if (
          res?.response?.data?.statusCode === 401 &&
          res?.response?.data?.message === 'wrong password'
      ) {
        setError(true);
      }
      console.log('err');
    }
    console.log('pressed');
  };

  const forgotPasswordPage = () => {
    dispatch(switchBeetwenComponent(SideBarScreen.FORGOTPASSWORD));
  };
  return (
    <>
      <form
        className={'auth_form'}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={'field_container'}>
          <label htmlFor=''>E-mail</label>
          <PrimaryInput
              placeholder={'your.email@gmail.com'}
              onChange={(e, d) => setEmail(d.value)}
              value={email}
          />
        </div>
        <div className={'field_container'}>
          <label htmlFor=''>Пароль</label>
          <PrimaryInput
            type={'password'}
            placeholder={'********'}
            onChange={(e, d) => setPassword(d.value)}
            value={password}
            error={error}
          />
          {error && <div className='error-text'>Неверный пароль</div>}
        </div>
        <div className='buttons_container'>
          <PrimaryBtn mods={modsType.MAXWIDTH} onClick={callLogin}>
            Вход
          </PrimaryBtn>
          <div className={'link_container'}>
            <a className={'link'} onClick={registrationPage}>
              Нет учетной записи? Зарегистрироваться
            </a>
            <a className={'link'} onClick={forgotPasswordPage}>
              Забыли пароль?
            </a>
          </div>
        </div>
      </form>
    </>
  );
};
