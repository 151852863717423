import { PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { SideBarScreen, switchBeetwenComponent } from '../../../Shared/Redux/features/sideBarSlice';
import { useState } from 'react';
import {
  authRegister,
  authRegistrationVerification,
  authRegistrationVerificationCodeRequest
} from '../../../Shared/Api/auth';
import { useSignIn } from 'react-auth-kit';
import ReactCodeInput from 'react-code-input';
import { PrimaryInput } from '../../../Shared/Ui/UIKit/input';

const inputStyle = {
  inputStyle:{
    fontSize:'24px',
    fontWeight:500,
    lineHeight:'32px',
    border:'2px solid #E6E6E6',
    borderRadius:'12px',
  }
}

export const Verification = () => {
  const dispatch = useAppDispatch();

  const email = useAppSelector((state) => state.sideBar.userRegistrationEmail);
  const personalArea = () => {
    dispatch(switchBeetwenComponent(SideBarScreen.PERSONALAREA));
  }

  const [code, setCode] = useState(null);

  const signIn = useSignIn();

  async function verifyRegistration() {
    try {
      const response = await authRegistrationVerification({
        email: email,
        code
      }).then((r) => r.data);
      console.log(response);
      localStorage.removeItem('cart');
      signIn({
        token: response.access_token,
        expiresIn: 9999999999,
        tokenType: 'Bearer',
        authState: response.user,
      });
      personalArea();
    } catch (error) {
      console.log(error);
    }
  }

  async function codeRequest() {
    try {
      const response = await authRegistrationVerificationCodeRequest(email);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <form
          className="forgot_password_form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
      >
        <div className="fields">
          <div className="field_container">
            <label> E-mail</label>
            <PrimaryInput value={email} disabled />
          </div>
          <div className="field_container">
            <label>Код полученный на e-mail</label>
            <ReactCodeInput
                name="code_input"
                inputMode="numeric"
                type="text"
                fields={4}
                {...inputStyle}
                onChange={setCode}
            />
            <div className="additional_btn" id="btn_2" onClick={codeRequest}>Отправить код еще раз</div>
          </div>

        </div>
        <PrimaryBtn onClick={verifyRegistration} disabled={!code || !(code > 999)}>Подтвердить</PrimaryBtn>
      </form>
    </>
  );
};
