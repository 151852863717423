import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProductById, getParamsProduct, getProductByNameOrVendoreCode } from '../../Api/product';
import { FilterCatalog, Product as ProductType, Meta } from '../../Types/index';

interface ProductState {
  isLoadingCatalog: boolean;
  isLoadingProduct: boolean;
  isLoadingProductName: boolean;
  productById: ProductType;
  productData: {
    filters: {
      availableCategories: { id: number; category: string }[];
      availableGearRatios: { id: number; gear_ratio: string | null }[];
      availableInterfaces: { id: number; interface_tool: string }[];
      availableTypes: { id: number; type: string }[];
    };
    products: ProductType[];
    meta: Meta;
  };
  productByName: ProductType[];
}

const initialState: ProductState = {
  productData: {
    filters: {
      availableCategories: [{ id: 0, category: 'test' }],
      availableGearRatios: [{ id: 0, gear_ratio: '' }],
      availableInterfaces: [{ id: 0, interface_tool: '' }],
      availableTypes: [{ id: 0, type: '' }],
    },
    products: [],
    meta: {
      currentPage: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  isLoadingCatalog: true,
  isLoadingProduct: true,
  isLoadingProductName: false,
  productById: {
    id: 0,
    vendor_code: '',
    name: '',
    amount: '',

    equipment: [
      {
        id: 0,
        amount: '',
        vendor_code: '',
        description: '',
        category_id: { category: '', id: 0, models: [] },
        price: '',
        quantity: 0,
        type_id: {
          id: 0,
          type: '0',
        },
        isOrder: false,
      },
    ],
    torque_max: '',
    speed_max: '',
    power_max: '',
    internal_coolant: '',
    interface_tool_id: {
      interface_tool: '',
      id: 0,
    },
    price: '',
    category_id: {
      category: '',
      id: 0,
      models: [],
    },
    type_id: {
      type: '',
      id: 0,
    },
    quantity: 0,
    gear_ratio_id: {
      gear_ratio: '',
      id: 0,
    },
    weight: null,
    pngIzoUrl: null,
    isOrder: false,
    deliveryTime: '',
  },
  productByName: [],
};

export const setProductForParams = createAsyncThunk(
  'productFetchByParams',
  (params: FilterCatalog) => getParamsProduct(params),
);
export const setProductById = createAsyncThunk('productFetchById', (id: string) =>
  getProductById(id),
);

export const setProductByNameOrVendoreCode = createAsyncThunk(
  'product/fetchByByNameOrVendoreCode',
  (name: string) => getProductByNameOrVendoreCode(name),
);

export const Product = createSlice({
  name: 'product',
  initialState,
  reducers: {
    productByNameReducer: (state) => {
      state.productByName = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProductById.pending, (state) => {
      state.isLoadingProduct = true;
    });
    builder.addCase(setProductById.fulfilled, (state, action) => {
      state.productById = action.payload;
      state.isLoadingProduct = false;
      window.scrollTo(0, 0);
    });
    builder.addCase(setProductForParams.pending, (state, action) => {
      state.isLoadingCatalog = true;
    });
    builder.addCase(setProductForParams.fulfilled, (state, action) => {
      state.productData = action.payload;
      state.isLoadingCatalog = false;
      window.scrollTo(0, 0);
    });
    builder.addCase(setProductForParams.rejected, (state, action) => {
      state.isLoadingCatalog = false;
    });
    builder.addCase(setProductByNameOrVendoreCode.pending, (state, action) => {
      state.isLoadingProductName = true;
    });
    builder.addCase(setProductByNameOrVendoreCode.fulfilled, (state, action) => {
      state.isLoadingProductName = false;
      state.productByName = action.payload;
      window.scrollTo(0, 0);
    });
    builder.addCase(setProductByNameOrVendoreCode.rejected, (state, action) => {
      state.isLoadingProductName = false;
    });
  },
  // [setProductByNameOrVendoreCode.fulfilled]:(state,action) => {
  //     state.productByName = action.payload
  // }
});
export const { productByNameReducer } = Product.actions;
export default Product.reducer;
