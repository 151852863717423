import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOptionalEquipment } from '../../Api/optionalEquipment/index';
import { FiltersMachine } from './filters';
import {
  OptionalEquipmentCategory,
  OptionalEquipment,
  OptionalEquipmentType,
  Meta,
} from '../../Types';

const optionalEquipmentsType: OptionalEquipmentType[] = [
  {
    type: '0',
    id: 0,
  },
];
const optionalEquipmentsCategory: OptionalEquipmentCategory[] = [
  {
    category: '0',
    id: 0,
  },
];
const optionalEquipments: OptionalEquipment[] = [
  {
    id: 0,
    amount: '0',
    category_id: {
      category: '0',
      id: 0,
      models: [],
    },
    description: '0',
    price: '0',
    type_id: {
      id: 0,
      type: '0',
    },
    vendor_code: '0',
    quantity: 0,
    isOrder: false,
  },
];
const meta: Meta = {
  currentPage: 0,
  itemCount: 0,
  itemsPerPage: 0,
  totalItems: 0,
  totalPages: 0,
};
const initialState = {
  optionalEquipmentsData: {
    optionalEquipments,
    optionalEquipmentsType,
    optionalEquipmentsCategory,
    meta,
  },
};

export const setOptionalEquipment = createAsyncThunk(
  'fetch/optionalEquipment',
  (params: FiltersMachine) => getOptionalEquipment(params),
);

const OptionalEquipmentsSlice = createSlice({
  name: 'optionalEquipments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setOptionalEquipment.fulfilled, (state, action) => {
      state.optionalEquipmentsData.optionalEquipments = action.payload.items;
      state.optionalEquipmentsData.optionalEquipmentsType = action.payload.filters.availableTypes;
      state.optionalEquipmentsData.optionalEquipmentsCategory =
        action.payload.filters.availableCategories;
      state.optionalEquipmentsData.meta = action.payload.meta;
      window.scrollTo(0, 0);
    });
  },
});

export const {} = OptionalEquipmentsSlice.actions;

export default OptionalEquipmentsSlice.reducer;
