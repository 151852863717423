import { ItemCardFactory } from '../../../Entity/ItemCard/Ui/ItemCardFactory/ItemCardFactory';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { AddToBasket } from '../../../Features/addToBasket/Ui';
import { Divider } from 'semantic-ui-react';
import downloadIcon from '../../../assets/icons/basketPage/download-arrow.svg';
import { PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import { Empty } from '../../../Widgets/Empty/Ui';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { numberWithSpaces } from '../../../Shared/Helpers/functions/numbersWithSpaces';
import { deleteCartItem, getSpecificationPdf, getSpecificationPdfNoAuth } from '../../../Shared/Api/basket';
import LoadingBar from 'react-top-loading-bar';
import { postOrder } from '../../../Shared/Api/order';
import { OrderModal } from '../../../Entity/OrderModal/Ui';
import { useIsAuthenticated } from 'react-auth-kit';
import { ModalsName, onOpen } from '../../../Shared/Redux/features/modalSlice';
import { OrderSuccessModal } from '../../../Entity/OrderSuccessModal/Ui';
import { openSideBar, SideBarScreen } from '../../../Shared/Redux/features/sideBarSlice';
import closeIcon from '../../../assets/icons/close-blue.svg';
import { emptyBasket, setFullBasket } from '../../../Shared/Redux/features/basketSlice';

export const BasketPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();
  const loadingRef = useRef(null);
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [company, setCompany] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [isEmpty, setEmpty] = useState(null);
  const { basket } = useAppSelector((state) => state.basket);
  const navigate = useNavigate();
  const redirectToCatalog = () => {
    navigate('/catalog');
  };

  const onBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  };

  const getSpec = async () => {
    loadingRef.current.continuousStart();
    let response;
    // console.log('is auth', isAuthenticated());
    if (isAuthenticated()) {
      response = await getSpecificationPdf();
    } else {
      response = await getSpecificationPdfNoAuth(basket);
    }

    loadingRef.current.complete();
    if (response.s3Key) {
      window.open(process.env.REACT_APP_ASSETS_URL + '/' + response.s3Key, '_blank');
    }
  };

  async function clearBasket() {
    if (isAuthenticated()) {
      for (const item of basket) {
        await dispatch(setFullBasket(await deleteCartItem((item as any).cartId)));
      }
    }
    dispatch(emptyBasket());
  }

  useEffect(() => {
    // async function mounted() {
    //   if (!company) {
    //     const response = await getOrganization();
    //     if (response.inn) setCompany(true);
    //   }
    // }
    // mounted();
    let count = 0,
      price = 0;
    basket.map((e: any) => {
      count += e.quantity;
      price += +e.price * e.quantity;
    });
    setPrice(price);
    setTax(price * 0.2);
  }, [basket]);
  async function create() {
    if (isAuthenticated()) {
      const order = await postOrder();
      if (order.id) {
        setOrderId(order.id);
        dispatch(emptyBasket());
        dispatch(onOpen(ModalsName.ORDERSUCCESSMODAL));
      }
    } else {
      if (window.innerWidth <= 600) {// mobile width SM
        document.scrollingElement.scrollTop = 0;
        dispatch(openSideBar(SideBarScreen.CONTACTS));
      } else {
        dispatch(onOpen(ModalsName.ORDERMODAL));
      }
    }
  }

  React.useEffect(() => {
    // @ts-ignore
    ym(93823936, 'hit', 'basket', {params:{
        title: 'Корзина',
        referer: 'https://innotechmet.ru/basket'
      }});
  }, []);

  useEffect(() => {
    setEmpty(basket.length === 0);
  }, [basket]);
  useEffect(() => {
    const footerEl = document.querySelector('.footer') as HTMLElement;
    if (!footerEl) return;
   if (isEmpty) {
     footerEl.style.display = 'none';
   } else {
     footerEl.style.display = 'block';
   }
  }, [isEmpty]);

  return (
    <>
      <LoadingBar color='white' ref={loadingRef} />
      <div className={`basket-page ${isEmpty ? 'empty' : ''}`}>
        <div className='wrapper'>
          <div className='title-container'>
            <h3 className='title'>Корзина</h3>
            <div className={'title-button'} onClick={clearBasket}><img src={closeIcon} alt="" />Очистить корзину</div>
            {/*<div className='close-icon' onClick={onBack}><img src={closeIcon} alt="" /></div>*/}
          </div>
          {isEmpty ? (
            <Empty
              title={'В корзине товаров нет'}
              buttonContent={'Посмотреть каталог'}
              onClick={redirectToCatalog}
            />
          ) : (
            <div className='content'>
              <div className='left-side'>
                {basket.map((item: any) => (
                  /*@ts-ignore*/
                  <ItemCardFactory
                      key={item.id}
                    product={item}
                    type={item.type}
                    features={<AddToBasket product={item} type={item.type} />}
                  />
                ))}
              </div>
              <div className='right-side'>
                <div className='price-container'>
                  <p className='title'>Итого</p>
                  <h4 className='price'>{numberWithSpaces(price.toString())} ₽</h4>
                  <h5 className='ndc'>
                    В том числе НДС 20% <br />
                    {numberWithSpaces(tax.toString())} ₽
                  </h5>
                </div>
                <div>
                  <Divider />
                </div>
                <div className='btn-container'>
                  <PrimaryBtn onClick={getSpec}>
                    Спецификация
                    <img src={downloadIcon} alt='' style={{ marginLeft: '10px' }} />
                  </PrimaryBtn>
                  {/*<PrimaryBtn mods={!company ? modsType.NOTACTIVE : undefined} onClick={create}>*/}
                  <PrimaryBtn onClick={create}>Запросить КП</PrimaryBtn>
                </div>
                {/*{!company && (*/}
                {/*  <div className='control-price'>*/}
                {/*    <h4>Для формирования счета заполните</h4>*/}
                {/*    <h5>клиентские данные</h5>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
            </div>
          )}
        </div>
      </div>
      <OrderModal />
      <OrderSuccessModal
          onClose={() => {
            navigate('/order/' + orderId);
            setOrderId(null);
          }}
      />
    </>
  );
};
