import Header from "../../Widgets/Header/Ui";
import {Outlet} from "react-router-dom";
import {Footer} from "../../Widgets/Footer/Ui";
import { SideBar } from "../../Widgets/SideBar/Ui";
import { ProfilePage } from "../../Pages/Profile/Ui";
import { Auth } from "../../Features/Auth/Ui/indx";
import { Registration } from "../../Features/Registration/Ui";
import { ClientDataPage } from "../../Pages/ClientData/Ui";
import { PersonalArea } from "../../Pages/PersonalArea/Ui";
import { useAppSelector } from "../../Shared/Redux/hooks";
import { SideBarScreen } from "../../Shared/Redux/features/sideBarSlice";
import { ForgotPassword } from "../../Features/ForgotPassword/Ui";
import React from "react";
import { OrderModal } from '../../Entity/OrderModal/Ui';
import MobileSearch from '../../Widgets/Header/Ui/mobileSearch';
import { Verification } from '../../Features/Verification/Ui';

const SideBarFactory = () =>{ 
    const {titleComponent} = useAppSelector(state => state.sideBar);
    
    switch(titleComponent){ 
        case `${SideBarScreen.AUTH}`: 
            return <Auth />
            break; 
        case `${SideBarScreen.REGISTRATION}`: 
            return <Registration />;
            break; 
        case `${SideBarScreen.PERSONALAREA}`: 
            return <PersonalArea />;
            break;
        case `${SideBarScreen.PROFILE}`: 
            return <ProfilePage />; 
            break;
        case `${SideBarScreen.CLIENTDATA}`: 
            return <ClientDataPage />;
            break;      
        case `${SideBarScreen.FORGOTPASSWORD}`: 
            return <ForgotPassword />; 
            break;
        case `${SideBarScreen.CONTACTS}`:
            return <OrderModal isMobile />;
            break;
        case `${SideBarScreen.MOBILESEARCH}`:
            return <MobileSearch />;
            break;
        case `${SideBarScreen.VERIFICATION}`:
            return <Verification />
            break;
        default : <Auth />;           
    }
}
const RootLayout = () => {
    return (
        <>
            <Header />
            <main style={{flexGrow:1}}>
                <Outlet></Outlet>
            </main>
            <SideBar>
                {SideBarFactory()}
            </SideBar>
            <Footer />
        </>
    );
}
export default RootLayout;