import { PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import ReactCodeInput from 'react-code-input'
import { PrimaryInput } from '../../../Shared/Ui/UIKit/input';
import './index.scss';
import { useAppDispatch } from '../../../Shared/Redux/hooks';
import { SideBarScreen, switchBeetwenComponent } from '../../../Shared/Redux/features/sideBarSlice';
import { useState } from 'react';
import { authResetPasswordCodeRequest, authResetPasswordNew } from '../../../Shared/Api/auth';
import { useSignIn } from 'react-auth-kit';

const inputStyle = { 
    inputStyle:{ 
        fontSize:'24px', 
        fontWeight:500,
        lineHeight:'32px',
        border:'2px solid #E6E6E6',
        borderRadius:'12px',
    }
}
export const ForgotPassword = () =>{
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [isEmail, setIsEmail] = useState(null);
    const [code, setCode] = useState(null);
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');

    const personalAreaPage = () => { 
        dispatch(switchBeetwenComponent(SideBarScreen.PERSONALAREA));
    };

    const signIn = useSignIn();

    async function passwordChange() {
        try {
            const response = await authResetPasswordNew({
                email,
                code,
                password
            }).then((r) => r.data);
            console.log(response);
            localStorage.removeItem('cart');
            signIn({
                token: response.access_token,
                expiresIn: 9999999999,
                tokenType: 'Bearer',
                authState: response.user,
            });
            personalAreaPage();
        } catch (error) {
            console.log(error);
        }
    }

    async function codeRequest() {
        try {
            const response = await authResetPasswordCodeRequest(email);
            console.log(response);
            setIsEmail(true);
        } catch (error) {
            console.log(error);
        }
    }

    async function onClick() {
        if (!isEmail && email) {
            codeRequest();
        } else {
            passwordChange();
        }
    };

    return ( 
        <>
            <form
                className="forgot_password_form"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="fields">
                    <div className="field_container">
                        <label>E-mail</label>
                        <PrimaryInput
                            value={email}
                            disabled={isEmail}
                            placeholder={'your.email@gmail.com'}
                            onChange={(e, d) => setEmail(d.value)}
                        />
                    </div>
                    {isEmail &&
                        <>
                            <div className="field_container">
                                <label>Код полученный на e-mail</label>
                                <ReactCodeInput
                                    name="code_input"
                                    inputMode="numeric"
                                    type="text"
                                    fields={4}
                                    {...inputStyle}
                                    onChange={setCode}
                                />
                                <div className="additional_btn" id="btn_2" onClick={codeRequest}>Отправить код еще раз</div>
                            </div>
                            <div className={'field_container'}>
                                <label htmlFor=''>Новый пароль</label>
                                <PrimaryInput
                                    type={'password'}
                                    placeholder={'****'}
                                    onChange={(e, d) => setPassword(d.value)}
                                    value={password}
                                />
                            </div>
                            <div className={'field_container'}>
                                <label htmlFor=''>Потвердите пароль</label>
                                <PrimaryInput
                                    type={'password'}
                                    placeholder={'****'}
                                    onChange={(e, d) => setPasswordCheck(d.value)}
                                    value={passwordCheck}
                                />
                            </div>
                        </>
                    }

                </div>
                <PrimaryBtn
                    onClick={onClick}
                    disabled={isEmail ? !code || !password || password !== passwordCheck : !email}
                >
                    {isEmail ? 'Далее' : 'Получить код на e-mail'}
                </PrimaryBtn>
            </form>
        </>
    )
}
