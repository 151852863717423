import {PrimaryInput} from "../../../Shared/Ui/UIKit/input";
import { Form, TextArea } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import iconDropZone from "../../../assets/icons/contactForm/dropzoneIcon.svg";
import {modsType, PrimaryBtn} from "../../../Shared/Ui/UIKit/button/index";
import "./index.scss";

export const ContactFormPage = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  return (
    <section className="contact-form-page">
      <div className="wrapper">
        <h2 className="title">Заполните форму обращения</h2>
        <Form>
          <div className="container">
            <Form.Field required>
              <PrimaryInput placeholder={"ФИО"} />
            </Form.Field>
            <Form.Field required>
              <PrimaryInput error={true} placeholder={"Телефон*"} />
            </Form.Field>
          </div>
          <div className="container">
            <Form.Field required>
              <TextArea placeholder={"Требования покупателя"} />
              <h4 className="description">
                Опишите характер дефекта. При каких условиях был обнаружен
                дефект? Каким образом выявленный дефект влияет на эксплуатацию
                товара?
              </h4>
            </Form.Field>
            <Form.Field required>
              <TextArea placeholder={"Описание дефекта"} />
              <h4 className="description">
                Как бы вы хотели, чтобы компания решила ситуацию?
              </h4>
            </Form.Field>
          </div>
          <div className="container">
            <section className="dropzone-div">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <img src={iconDropZone} alt="" />
                <h3 className="title">Прикрепить фото/видео</h3>
                <h4 className="description">
                  .jpg, .jpeg, .png, .mp4, .mov, .wmv, .avi и .mpg менее 10 MB
                </h4>
              </div>
              <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
              </aside>
            </section>

            <div className="requirement__container">
              <h3 className="title">
                К обращению необходимо приложить следующие материалы:
              </h3>
              <ul className="list_requirements">
                <li className="list_requirements__requirement">
                  - фото товара в упаковке;
                </li>
                <li className="list_requirements__requirement">
                  - фото повреждений упаковки (при наличии);
                </li>
                <li className="list_requirements__requirement">
                  - фото товарного чека (акта приема-передачи, УПД) с подписью
                  покупателя;
                </li>
                <li className="list_requirements__requirement">
                  - фото дефекта с расстояния 20-30 сантиметров;
                </li>
                <li className="list_requirements__requirement">
                  - видео, если необходимо продемонстрировать работу механизмов.
                </li>
              </ul>
            </div>
          </div>
          <div className="container btn">
            <PrimaryBtn mods={modsType.NOTACTIVE}>Отправить заявку</PrimaryBtn>
          </div>
        </Form>
      </div>
    </section>
  );
};
