import { createSlice } from '@reduxjs/toolkit';
import { AuthRegisterParams } from '../../Api/auth';

export enum SideBarScreen {
  AUTH = 'Вход',
  REGISTRATION = 'Регистрация',
  PERSONALAREA = 'Личный кабинет',
  PROFILE = 'Профиль',
  CLIENTDATA = 'Клиентские данные',
  FORGOTPASSWORD = 'Восстановление',
  CONTACTS = 'Контакты',
  MOBILESEARCH = 'Поиск',
  VERIFICATION = 'Введите код e-mail' // space is for unique name
}
interface SideBarState {
  isOpen: boolean;
  titleComponent: SideBarScreen;
  isBasketReturn: boolean;
  userRegistrationEmail?: string;
}
const initialState: SideBarState = {
  isOpen: false,
  titleComponent: SideBarScreen.AUTH,
  isBasketReturn: false,
};

export const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    openSideBar: (state, action) => {
      state.titleComponent = action.payload;
      state.isOpen = true;
    },
    closeSideBar: (state) => {
      state.isOpen = false;
      state.isBasketReturn = false;
    },
    setBasketReturn: (state, action) => {
      state.isBasketReturn = action.payload;
    },
    setUserRegisterEmail: (state, action) => {
      state.userRegistrationEmail = action.payload;
    },
    switchBeetwenComponent: (state, action) => {
      if (state.titleComponent !== SideBarScreen.CONTACTS) {
        state.isBasketReturn = false;
      }
      state.titleComponent = action.payload;
    },
  },
  extraReducers: {},
});
export const { openSideBar, closeSideBar, switchBeetwenComponent, setBasketReturn, setUserRegisterEmail } = sideBarSlice.actions;
export default sideBarSlice.reducer;
