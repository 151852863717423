interface DropDownFields {
    key:number;
    text:string;
    value:string;
}
export function transformToDropDownData (list:Array<any>,nameOfKey:string):Array<DropDownFields> {
    return [{key: 0,text:'Все',value:undefined}].concat(list.map(item => ({
        key:item.id,
        text:item[nameOfKey],
        value:item.id,
        ...item
    })))
}