import { ItemCardFactory } from '../../../../../Entity/ItemCard/Ui/ItemCardFactory/ItemCardFactory';
import { AddToBasket } from '../../../../../Features/addToBasket/Ui';
import { useAppDispatch, useAppSelector } from '../../../../../Shared/Redux/hooks';
import { PaginationProps } from 'semantic-ui-react';
import { changeFiltersMachine } from '../../../../../Shared/Redux/features/filters';
import { PrimaryPagination } from '../../../../../Shared/Ui/UIKit/pagination';
import { MouseEvent } from 'react';

export const CatalogMachines = () => {
  const { optionalEquipments, meta } = useAppSelector(
    (state) => state.optionalEquipments.optionalEquipmentsData,
  );
  const dispatch = useAppDispatch();
  const onPageChange = (e: MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
    dispatch(changeFiltersMachine({ name: 'page', selectedItem: Number(data.activePage) }));
  };
  return (
    <>
      {optionalEquipments.map((item) => (
        <ItemCardFactory
          product={item}
          features={<AddToBasket type='optionalEquipment' product={item} />}
          key={item.id}
          type='optionalEquipment'
        />
      ))}
      <div className={'pagination_container'}>
        <PrimaryPagination
          activePage={meta.currentPage}
          totalPages={meta.totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
};
