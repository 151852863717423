import React from 'react';
import logo from '../../../assets/icons/footerLogo.svg';
import tgIcon from '../../../assets/icons/tg.svg';
import phoneIcon from '../../../assets/icons/phone-icon.svg';
import emailIcon from '../../../assets/icons/email-icon.svg';
import visionerIcon from '../../../assets/icons/visioner-icon.svg';
import visioners from '../../../assets/icons/visioners.svg';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { appOptions } from '../../../Shared/options';
//@ts-ignore
import pdf from '../../../assets/pdf/policy.pdf'

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="footer-content">
          <h2 className="footer-logo" onClick={() => navigate("/")}>
            <img src={logo} alt="" />
          </h2>

          <ul className="list-pages">
            <li className="list-pages__page" onClick={() => navigate("/")}>Главная</li>
            <li className="list-pages__page" onClick={() => navigate("/company")}>О компании</li>
            <li className="list-pages__page" onClick={() => navigate("/catalog")}>Каталог</li>
            {/*<li className="list-pages__page" onClick={() => navigate("/")}>Скачать каталог</li>*/}
          </ul>

          <ul className="list-contacts">
            <h3 className="title">Контакты ООО ПО «ИнноТехМет»:</h3>
            {/* <li className="list-contacts__contact">
              <a href="https://t.me/ITM_catalog_bot">
                <img src={tgIcon} alt="" />
                <a href={appOptions.contacts.telegramBotUrl} target={'_blank'}>ITM_catalog_bot.t.me</a>
              </a>
            </li> */}
            <li className="list-contacts__contact">
              <img src={emailIcon} alt="" />
              {<div dangerouslySetInnerHTML={{ __html: appOptions.contacts.emailUrl }}></div>}
            </li>
            <li className="list-contacts__contact">
              <img src={phoneIcon} alt="" />
              {<div><span dangerouslySetInnerHTML={{ __html: appOptions.contacts.phoneUrl }} /> доб 201</div>}
            </li>
            {/*<li className="list-contacts__contact">*/}
            {/*  <img src={measureIcon} alt="" />*/}
            {/*  Обращение в отдел качества*/}
            {/*</li>*/}
          </ul>
          <div className="right">
            <div className="information">
              <h3 className="title">Информация для покупателей:</h3>
              <div className="contact">
                <a href={pdf} download>Политика конфиденциальности</a>
                {/*<a>Договор оферты для юридических лиц и ИП</a>*/}
              </div>
            </div>
            <div className="company">
                {/* <a className="visioner" href={'https://visioners.ru/'} target={'_blank'}>
                  <img src={visionerIcon} alt="" />
                  <h3>сайт разработан</h3>
                  <img src={visioners} alt="" />
                </a> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
