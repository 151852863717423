import { useParams } from 'react-router-dom';
import './index.scss';
import { useEffect, useRef, useState } from 'react';
import { getOrder } from '../../../Shared/Api/order';
import LoadingBar from 'react-top-loading-bar';
import { ItemCardFactory } from '../../../Entity/ItemCard/Ui/ItemCardFactory/ItemCardFactory';
import { numberWithSpaces } from '../../../Shared/Helpers/functions/numbersWithSpaces';
import { Divider } from 'semantic-ui-react';
import { PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import downloadIcon from '../../../assets/icons/basketPage/download-arrow.svg';
import {getSpecificationOrder, getSpecificationPdf, getSpecificationPdfNoAuth} from "../../../Shared/Api/basket";
import {useIsAuthenticated} from "react-auth-kit";

export const CompleteOrder = () => {
  const isAuthenticated = useIsAuthenticated();
  const loadingRef = useRef(null);
  const [order, setOrder] = useState({
    id: undefined,
    companyName: undefined,
    trackNumber: undefined,
    price: undefined,
    status: undefined,
    createdAt: Date,
    items: [],
  });
  const [count, setCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [date, setDate] = useState(new Date());
  const [reqComplete, setReqComplete] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (reqComplete) return;
    async function set() {
      loadingRef.current.continuousStart();
      const order = await getOrder(parseInt(id));
      setOrder(order);
      let count = 0,
        price = 0;
      order.items.map((e: any) => {
        count += e.quantity;
        price += +e.price * e.quantity;
      });
      // if (reqComplete) {
      //   console.log('complete');
      //   return;
      // }
      setCount(count);
      setPrice(price);
      setTax(price * 0.2);
      setReqComplete(true);
      setDate(new Date(order.createdAt));
      loadingRef.current.complete();
    }

    set();
  });

  const getSpec = async () => {
    loadingRef.current.continuousStart();
    const response = await getSpecificationOrder(order.id)

    loadingRef.current.complete();
    if (response.s3Key) {
      window.open(process.env.REACT_APP_ASSETS_URL + '/' + response.s3Key, '_blank');
    }
  };

  return (
    <>
      <LoadingBar color='white' ref={loadingRef} />
      {reqComplete && (
        <div className={'complete-order'}>
          <div className='wrapper'>
            <h3 className='title'>
              Заказ №
              {order.id +
                ' от ' +
                ('0' + date.getDate()).slice(-2) +
                '.' +
                ('0' + date.getMonth()).slice(-2) +
                '.' +
                date.getFullYear() +
                ' г.'}
            </h3>

            <div className='content'>
              <div className='left-side'>
                <div className='about'>
                  <div className={'status-container'}>
                    <p className='title'>Статус</p>
                    {order.status === 'CHECK' ||
                    order.status === 'PAY' ||
                    order.status === 'DELIVERY' ? (
                      <div
                        className={
                          'status ' +
                          (order.status === 'CHECK'
                            ? 'invoice-request'
                            : order.status === 'PAY'
                            ? 'payment'
                            : 'delivery')
                        }
                      >
                        <div className='status-content'>
                          <div className='img' />
                          <p>Запрос счета</p>
                        </div>
                        <div className='status-content'>
                          <div className='img' />
                          <p>Оплата</p>
                        </div>
                        <div className={'status-content'}>
                          <div className='img' />
                          <p>Доставка</p>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {order.status === 'COMPLETED' ? (
                          <div className='status success'>
                            <div className='status-content'>
                              <div className='img' />
                              <p>Выполнен</p>
                            </div>
                          </div>
                        ) : order.status === 'CANCELED' ? (
                          <div className='status canceled'>
                            <div className='status-content'>
                              <div className='img' />
                              <p>Отменен</p>
                            </div>
                          </div>
                        ) : (
                          <div className='status invoice-request'>
                            <div className='status-content'>
                              <div className='img' />
                              <p>В процессе</p>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='description'>
                    <div><span className='title'>Номер счета:{' '}</span><span>{order.id}</span></div>
                    <div><span className='title'>Транспортная компания:{' '}</span><span>{order.companyName ? order.companyName : '...'}</span></div>
                    <div>
                      <span className='title'>Трек номер транспортной компании:{' '}</span>
                      <span>{order.trackNumber ? order.trackNumber : '...'}</span>
                    </div>
                  </div>
                </div>
                {order.items.map((item: any) => (
                  /*@ts-ignore*/
                  <ItemCardFactory
                    product={item}
                    type={item.type}
                    features={
                      <div className='price__container'>
                        <h3 className='price'>{item.quantity} шт.</h3>
                      </div>
                    }
                  />
                ))}
              </div>
              <div className='right-side'>
                <div className='price-container'>
                  <p className='title'>Итого {count} товаров на сумму</p>
                  <h4 className='price'>{numberWithSpaces(price.toString())} ₽</h4>
                  <h5 className='ndc'>
                    В том числе НДС 20% <br />
                    {numberWithSpaces(tax.toString())} ₽
                  </h5>
                </div>
                <div>
                  <Divider />
                </div>
                <PrimaryBtn onClick={getSpec}>
                  Спецификация
                  <img src={downloadIcon} alt='' style={{ marginLeft: '10px' }} />
                </PrimaryBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
