import { ItemCardFactory } from "../../../Entity/ItemCard/Ui/ItemCardFactory/ItemCardFactory"
import {Empty} from "../../../Widgets/Empty/Ui";
import {useAppDispatch} from "../../../Shared/Redux/hooks";
import {useNavigate} from "react-router-dom";
import './index.scss';

export const FavoritePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const redirectToCatalog = () => {
        navigate('/catalog')
    }
    return (
        <>
            <div className={'favorite-page'}>
                <div className="wrapper">
                    <h3 className={'title'}>
                        Избранное
                    </h3>
                    <Empty
                        title={'В избранном товаров нет'}
                        buttonContent={'Посмотреть каталог'}
                        onClick={redirectToCatalog}
                    />
                </div>
            </div>
        </>
    )
}
