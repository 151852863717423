import {Checkbox} from "semantic-ui-react";
import {FC,FormEvent} from "react";
import './index.scss';
interface Props{
    value:string;
    label:string;
    checkedName:string,
    onChange:(e:FormEvent<HTMLInputElement>,data:any)=>void;
}
export const PrimaryCheckBox:FC<Props> = ({checkedName, label, value,onChange}) => {
      return <Checkbox
          radio
          label={label}
          name="checkboxRadioGroup"
          value={value}
          checked={value === checkedName}
          onChange={onChange}
          className="custom"
      />
}