import React, { SyntheticEvent, useEffect, useRef } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import close from '../../../../assets/icons/close.svg';
import company from '../../../../assets/icons/home-icon.svg';
import contact from '../../../../assets/icons/contacts-icon.svg';
import catalog from '../../../../assets/icons/catalog-icon.svg';
// import search from "../../../../assets/icons/search_mobile_menu.svg";
import userIcon from '../../../../assets/icons/user.svg';
import ordersIcon from '../../../../assets/icons/orders.svg';
import basketIcon from '../../../../assets/icons/basket-mobile-menu.svg';
import searchIcon from '../../../../assets/icons/search.svg';
import { useIsAuthenticated } from 'react-auth-kit';
import { useAppDispatch, useAppSelector } from '../../../../Shared/Redux/hooks';
import { openSideBar, SideBarScreen } from '../../../../Shared/Redux/features/sideBarSlice';
import { closeMobileMenu } from '../../../../Shared/Redux/features/mobileMenuSlice';

const MobileMenu = () => {
  const isOpen = useAppSelector((state) => state.mobileMenu.isOpen);
  const isFilterOpen = useAppSelector((state) => state.mobileFilters.isOpen);
  const isSideBarOpen = useAppSelector((state) => state.sideBar.isOpen);
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();

  const overlayRef = useRef(null);

  const onClick = (e: SyntheticEvent) => {
    if (overlayRef.current === e.target) {
      dispatch(closeMobileMenu());
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';
      if (window.innerWidth <= 600) {
        document.body.scrollTo({ top: 0 });
      }
    } else if (!isFilterOpen && !isSideBarOpen) {
      document.documentElement.style.overflow = 'auto'
    }
  }, [isOpen]);

  return (
    <div
        className="mb-wrapper"
        onClick={e => e.stopPropagation()} // to ignore in header
    >
      {isOpen && <div className="overlay" ref={overlayRef} onClick={onClick}/>}
      <div className={`mb menu ${isOpen ? "enable" : "disable"}`}>
        <div className="content">
          <div className="content__header">
            <h3>Меню</h3>
            <div className="close-icon" onClick={() => dispatch(closeMobileMenu())}>
              <img src={close} alt="" />
            </div>
          </div>
          {/*<div className="input_container" onClick={() => handleSearch()}>*/}
          {/*  <input disabled={true} placeholder="Поиск по артикулу" type="text" />*/}
          {/*</div>*/}
          <ul className="list__pages">
            {/*{isAuthenticated() &&*/}
            {/*    <li className="list__pages__page" onClick={() => {dispatch(closeMobileMenu());navigate("/myOrders")}}>*/}
            {/*      <img src={ordersIcon} alt=""/>*/}
            {/*      Мои заказы*/}
            {/*    </li>*/}
            {/*}*/}
            <li className="list__pages__page"  onClick={() => {dispatch(closeMobileMenu());navigate("/company")}}>
              <img src={company} alt=""/>
              О компании
            </li>
            <li className="list__pages__page"  onClick={() => {dispatch(closeMobileMenu());navigate("/contact")}}>
              <img src={contact} alt=""/>
              Контакты
            </li>
            <li
                className="list__pages__page"
                onClick={() => {
                  dispatch(openSideBar(isAuthenticated() ? SideBarScreen.PERSONALAREA : SideBarScreen.AUTH));
                  dispatch(closeMobileMenu());
                }}
            >
              <img src={userIcon} alt=""/>
              Личный кабинет
            </li>
            <li
                className="list__pages__page"
                onClick={() => {
                  dispatch(openSideBar(SideBarScreen.MOBILESEARCH));
                  dispatch(closeMobileMenu());
                }}
            >
              <img src={searchIcon} alt=""/>
              Поиск по коду
            </li>
            <li className="list__pages__page"  onClick={() => {dispatch(closeMobileMenu());navigate("/catalog")}}>
              <img src={catalog} alt=""/>
              Каталог
            </li>
            <li className="list__pages__page"  onClick={() => {dispatch(closeMobileMenu());navigate("/basket")}}>
              <img src={basketIcon} alt=""/>
              Корзина
            </li>
            {/*<li className="list__pages__page" onClick={() => {handlerMenu();navigate("/company")}}>*/}
            {/*  О компании*/}
            {/*</li>*/}
            {/*<li*/}
            {/*  className="list__pages__page"*/}
            {/*  onClick={() =>  {handlerMenu();navigate("/catalog")}}*/}
            {/*>*/}
            {/*  Онлайн-каталог*/}
            {/*</li>*/}
            {/*<li className="list__pages__page">*/}
            {/*  <a href={`${assetUrl}download-catalog/catalog.pdf`} download>Скачать каталог</a>*/}
            {/*</li>*/}
          </ul>
          {/*<div className="mobile-logo">*/}
          {/*  <img src={mobileLogo} alt="" />*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
