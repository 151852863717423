import {OptionalEquipment} from "../../../../Shared/Types";
import {FC} from "react";
import {ItemCardFactory} from "../../../../Entity/ItemCard/Ui/ItemCardFactory/ItemCardFactory";
import {AddToBasket} from "../../../../Features/addToBasket/Ui";
import './index.scss';
interface Props {
    optional:OptionalEquipment[],
}

export const ProductOptionalEquipment : FC<Props> =({optional}) => {

    return (
        <div className='optional-equipment'>
            <div className={'name'}>Комплектующие</div>
            {
                optional.map(item => (
                    <ItemCardFactory
                        product={item}
                        type={'optionalEquipment'}
                        features={<AddToBasket type="optionalEquipment" product={item} />}
                    />
                ))
            }
        </div>
    );
}