import React from 'react';
import userIcon from '../../../../assets/icons/user-icon.svg';
import { useAppDispatch } from '../../../../Shared/Redux/hooks';
import { openSideBar, SideBarScreen } from '../../../../Shared/Redux/features/sideBarSlice';
import './index.scss';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';

export const User = () => {
  const auth = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();

  return (
    <>
      <div
        className='basket'
        onClick={() =>
          dispatch(openSideBar(isAuthenticated() ? SideBarScreen.PERSONALAREA : SideBarScreen.AUTH))
        }
      >
        <img src={userIcon} alt='' className='icon' />
        {!isAuthenticated() ? (
          <h3 className='title'>Войти</h3>
        ) : (
          <h3 className='title'>{auth().name}</h3>
        )}
      </div>
    </>
  );
};
