import {FC} from "react";
import emptyPhoto from '../../../assets/photos/emptyPage/empty.svg';
import emptyPhotoMobile from '../../../assets/photos/emptyPage/empty_mobile.svg';
import { PrimaryBtn } from "../../../Shared/Ui/UIKit/button";
import './index.scss';

interface Props{
    title:string,
    buttonContent:string,
    onClick:()=>void
}
export const Empty:FC<Props> = (
    {
        buttonContent,
        onClick,
        title,
    }
) => {
 return (
     <div className={'empty'}>
         <div className={'image_container'}>
             <img className={'image'} src={emptyPhoto} alt=""/>
             <img className={'image mobile'} src={emptyPhotoMobile} alt=""/>
         </div>
         <div className={'title_container'}>
             <h3 className={'title'}>{title}</h3>
             <PrimaryBtn onClick={onClick}>{buttonContent}</PrimaryBtn>
         </div>
     </div>
 )
}
