import { FC, ReactNode } from 'react';
import { Card } from '../../../../Shared/Ui/UIKit/card';
import { Product, OptionalEquipment } from '../../../../Shared/Types';
import { ItemCard } from '..';
import { Link, useNavigate } from 'react-router-dom';
import './OptionalEquipmentCard.scss';
import { numberWithSpaces } from '../../../../Shared/Helpers/functions/numbersWithSpaces';
//@ts-ignore
import testIzo from '../../../../assets/mainPagePhoto/testIzo.png';

interface OptionalEquipmentCardProps {
  product: OptionalEquipment;
  features: ReactNode;
}

export const OptionalEquipmentCard: FC<OptionalEquipmentCardProps> = ({ product, features }) => {
  const navigate = useNavigate();
  const assetsUrl = process.env.REACT_APP_ASSETS_URL;
  const img = () => (
    <img src={`${assetsUrl + '/' + product.vendor_code + '-PNG_EQUIPMENT.png'} `} alt='' />
  );
  const list = () => (
    <>
      <ul className='list optional'>
        <li className='item__vendor_code'>
          {/*<Link to={`/product/${product.id}`}>*/}
          {product?.vendor_code || '-'}
          {/*</Link>*/}
        </li>

        <li className='item__field'>
          <span className='title'>Категория: </span>
          <span className='description'>{product?.category_id.category || '-'}</span>
        </li>
        <li className='item__field'>
          <span className='title'>Тип: </span>
          <span className='description'>{product.type_id.type || '-'}</span>
        </li>
        <li className='item__price'>
          {numberWithSpaces(product.price.toString())} ₽
        </li>
        <li className='item__field about'>
          <span className='title'>Описание: </span>
          <span className='description'>{product.description || '-'}</span>
        </li>
      </ul>
    </>
  );
//@ts-ignore
  return <ItemCard list={list()} product={product} features={features} imgNode={img()}  isOptional />;
};
