import { createSlice } from '@reduxjs/toolkit';
import { IProduct } from '../../Types';
interface Rest {
  type: string;
}
interface BasketState {
  basket: IProduct[];
  deleteId: number;
}
const initialState: BasketState = {
  basket: [],
  deleteId: 0,
};

export const BasketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    setFullBasket: (state, action) => {
      console.log(`set full basket length=${action.payload.length}`);
      state.basket = action.payload;
    },
    addToBasketNoAuth: (state, action) => {
      state.basket.push(action.payload);
      localStorage.setItem('cart', JSON.stringify(state.basket));
    },
    changeBasketItemCountNoAuth: (state, action) => {
      state.basket.find(
        (e) => e.id === action.payload.id && e.type === action.payload.type,
      ).quantity = action.payload.quantity;
      localStorage.setItem('cart', JSON.stringify(state.basket));
    },
    deleteItemFromBasketNoAuth: (state, action) => {
      state.basket.splice(
        state.basket.findIndex((e) => e.id === action.payload.id && e.type === action.payload.type),
        1,
      );
      localStorage.setItem('cart', JSON.stringify(state.basket));
    },
    setBasket: (state, action) => {
      state.basket = action.payload;
      localStorage.setItem('cart', JSON.stringify(state.basket));
    },
    getBasket: (state) => {
      state.basket = JSON.parse(localStorage.getItem('basket'));
      console.log(state.basket);
    },
    addCountItem: (state, action) => {
      const exItem = state.basket.find((item) => item.id === action.payload);
      exItem.quantity = exItem.quantity + 1;
      localStorage.setItem('basket', JSON.stringify(state.basket));
    },
    removeCountItem: (state, action) => {
      const exItem = state.basket.find((item: any) => item.id === action.payload);
      exItem.quantity = exItem.quantity - 1;
      localStorage.setItem('basket', JSON.stringify(state.basket));
    },
    setDeleteId: (state, action) => {
      state.deleteId = action.payload;
    },
    deleteItem: (state) => {
      console.log(state.deleteId);
      const result = state.basket.filter((item) => {
        if (item.id !== state.deleteId) return item;
      });
      state.basket = result;
      localStorage.setItem('basket', JSON.stringify(state.basket));
    },
    emptyBasket: (state) => {
      state.basket = [];
      localStorage.setItem('basket', JSON.stringify(state.basket));
      localStorage.setItem('cart', JSON.stringify(state.basket));
    }
  },
});
export const {
  setBasket,
  getBasket,
  addCountItem,
  removeCountItem,
  deleteItem,
  setDeleteId,
  setFullBasket,
  addToBasketNoAuth,
  changeBasketItemCountNoAuth,
  deleteItemFromBasketNoAuth,
  emptyBasket
} = BasketSlice.actions;
export default BasketSlice.reducer;
