import { instance } from '../index';

export const sendPhone = async (phone: string) => {
  const response = await instance.post(
    'callback-request',
    {},
    {
      params: {
        phone,
      },
    },
  );
  return response.data;
};
