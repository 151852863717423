import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { closeSideBar } from '../../../Shared/Redux/features/sideBarSlice';
import closeIcon from '../../../assets/icons/close.svg';
import React, { FC, ReactNode, useEffect } from 'react';
import './index.scss';

interface Props{ 
    children:ReactNode
}
export const SideBar:FC<Props> = ({children}) => {
    const { isOpen,titleComponent } = useAppSelector((state) => state.sideBar);
    const isFilterOpen = useAppSelector((state) => state.mobileFilters.isOpen);
    const isMenuOpen = useAppSelector((state) => state.mobileMenu.isOpen);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isOpen) {
            document.documentElement.style.overflow = 'hidden';
            document.body.scrollTo({ top: 0 });
        } else if (!isMenuOpen && !isFilterOpen) {
            document.documentElement.style.overflow = 'auto'
        }
    }, [isOpen]);
    
    return (
      <>
        <div className={`side-bar ${isOpen ? 'active' :''}`}>
          <div className="overlay" onClick={()=>dispatch(closeSideBar())}></div>
          <div className="content">
            <div className="title_container">
              <h3  className="title">{titleComponent}</h3>
              <div className="close_icon" onClick={()=>dispatch(closeSideBar())}><img src={closeIcon} alt="" /></div>
            </div>
            {children}
          </div>
        </div>
      </>)
  };
  