import { PrimaryInput } from '../../../Shared/Ui/UIKit/input';
import { modsType, PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { setUserRegisterEmail, SideBarScreen, switchBeetwenComponent } from '../../../Shared/Redux/features/sideBarSlice';
import './index.scss';
import { useState } from 'react';
import { authRegister, authRegistrationVerificationCodeRequest } from '../../../Shared/Api/auth';

export const Registration = () => {
  const { basket } = useAppSelector((state) => state.basket);

  const dispatch = useAppDispatch();
  const authPage = () => {
    dispatch(switchBeetwenComponent(SideBarScreen.AUTH));
  };
  const verification = () => {
    dispatch(switchBeetwenComponent(SideBarScreen.VERIFICATION));
  };
  const forgotPasswordPage = () => {
    dispatch(switchBeetwenComponent(SideBarScreen.FORGOTPASSWORD));
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');

  async function codeRequest() {
    try {
      const response = await authRegistrationVerificationCodeRequest(email);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function registerUser() {
    try {
      const data = {
        name,
        email,
        phone,
        password,
        basket,
      };
      const response = await authRegister(data).then((r) => r.data);
      console.log(response);

      dispatch(setUserRegisterEmail(email));

      verification();
    } catch (error) {
      if (error.code === 401) {
        codeRequest();
        dispatch(setUserRegisterEmail(email));
        verification();
      }
      console.log(error);
    }
  }

  return (
    <>
      <form
        className={'registration_form'}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={'field_container'}>
          <label htmlFor=''>Имя</label>
          <PrimaryInput
            placeholder={'Имя Фамилия'}
            onChange={(e, d) => setName(d.value)}
            value={name}
          />
        </div>
        <div className={'field_container'}>
          <label htmlFor=''>email</label>
          <PrimaryInput
            placeholder={'your.email@gmail.com'}
            onChange={(e, d) => setEmail(d.value)}
            value={email}
          />
        </div>
        <div className={'field_container'}>
          <label htmlFor=''>Пароль</label>
          <PrimaryInput
            type={'password'}
            placeholder={'*******'}
            onChange={(e, d) => setPassword(d.value)}
            value={password}
          />
        </div>
        <div className={'field_container'}>
          <label htmlFor=''>Телефон</label>
          <PrimaryInput onChange={(e, d) => setPhone(d.value)} value={phone} />
        </div>
        <div className={'link_container'}>
          <a className={'link'} onClick={authPage}>
            Войти если уже есть аккаунт
          </a>
          <a className={'link'} onClick={forgotPasswordPage}>
            Забыли пароль?
          </a>
        </div>
        <div className={'footer_container'}>
          <div className={'polite_confidentiality'}>
            <h4 className={'title'}>
              * Отправляя заявку, я соглашаюсь с условиями Политики конфиденциальности и получением
              рекламно-информационных рассылок.
            </h4>
          </div>
          <PrimaryBtn mods={modsType.MAXWIDTH} onClick={registerUser} disabled={!name || !email || !phone || !password}>
            Получить код на e-mail
          </PrimaryBtn>
        </div>
      </form>
    </>
  );
};
