import React, { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { InputProps } from 'semantic-ui-react';
import { setProductByNameOrVendoreCode } from '../../../../Shared/Redux/features/product';
import { useAppDispatch, useAppSelector } from '../../../../Shared/Redux/hooks';
import { PrimaryInput } from '../../../../Shared/Ui/UIKit/input';
import searchIcon from '../../../../assets/icons/searchIcon.svg';
import './index.scss';
import { SearchResult } from '../searchResult';
import debounce from 'lodash.debounce';

const Search = () => {
  const [searchInput, setSearchInput] = useState('');
  const [dropDownMenuState, setDropDownMenuState] = useState(false);
  const { isLoadingProductName, productByName } = useAppSelector((state) => state.product);
  const dispatch = useAppDispatch();

  const onChange = (e: ChangeEvent, data: InputProps) => {
    const inputData = data.value;
    setSearchInput(inputData);

    if (inputData !== '') {
      debouncedChangeHandler(inputData);
      setDropDownMenuState(true);
    } else {
      setDropDownMenuState(false);
    }
  };

  const debouncedChangeHandler = useMemo(
    () => debounce((inputVal) => dispatch(setProductByNameOrVendoreCode(inputVal)), 300),
    [],
  );

  const searchIconEl = () => (
    <i className='icon'>
      <img src={searchIcon} width={24} height={24} alt='search-icon' />
    </i>
  );

  const isSearchResultExisting = () => {
    return productByName.length !== 0;
  };

  useEffect(() => {
    return () => {
      setSearchInput('');
      setDropDownMenuState(false);
      debouncedChangeHandler.cancel();
    };
  }, []);

  return (
    <div className={'search-container'}
         onBlur={() => setDropDownMenuState(false)}
         onFocus={() => searchInput && setDropDownMenuState(true)}
    >
      <PrimaryInput
        value={searchInput}
        loading={isLoadingProductName}
        onChange={onChange}
        placeholder={'Поиск по коду'}
        className={'search_input'}
        otherProps={{
          icon: !isLoadingProductName ? searchIconEl : '',
          iconPosition: 'left',
        }}
      />
      <div className={`my__drop-down ${dropDownMenuState ? 'active' : ''}`}>
        <ul className={`my__drop-down__list ${isSearchResultExisting() ? '' : 'overflow-hidden'}`}>
          {isSearchResultExisting() ? (
            productByName.map((product) => <SearchResult key={product.id} product={product} />)
          ) : (
            <div>Ничего не найдено</div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Search;
