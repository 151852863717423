import React from "react";
import basketIcon from '../../../../assets/icons/basket.svg';
import { Link, useNavigate } from "react-router-dom";
import {useAppSelector} from "../../../../Shared/Redux/hooks";
import './index.scss';

export const Basket = () => {
    const navigate = useNavigate();
    const {basket} = useAppSelector(state => state.basket);
    return (
        <>
            <Link className="basket" to={'/basket'}>
                <img src={basketIcon} alt="" className="icon" />
                <h3 className="title">Корзина</h3>
                {!!basket.length &&
                    <div className="basket_counter">
                        <h3 className="count">{basket.length}</h3>
                    </div>
                }
            </Link>
        </>
    )
}
