import React, { ChangeEvent, useEffect, useRef, useState, useMemo } from 'react';
import './index.scss';
import searchIcon from '../../../../assets/icons/searchIcon.svg';
import { PrimaryInput } from '../../../../Shared/Ui/UIKit/input';
import { useAppDispatch, useAppSelector } from '../../../../Shared/Redux/hooks';
import { SearchResult } from '../searchResult';
import { InputProps } from 'semantic-ui-react';
import { setProductByNameOrVendoreCode } from '../../../../Shared/Redux/features/product';
import debounce from 'lodash.debounce';

const MobileSearch = () => {
  const { isLoadingProductName, productByName } = useAppSelector((state) => state.product);
  const { isOpen } = useAppSelector((state) => state.sideBar);

  const dispatch = useAppDispatch();

  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState(false);
  const ref = useRef(null);

  const onChange = (e: ChangeEvent, data: InputProps) => {
    const inputData = data.value;
    setSearchInput(inputData);
    if (inputData !== '') {
      debouncedChangeHandler(inputData);
      setSearchResults(true);
    } else {
      setSearchResults(false);
    }
  };

  const debouncedChangeHandler = useMemo(
    () => debounce((inputVal) => dispatch(setProductByNameOrVendoreCode(inputVal)), 300),
    [],
  );

  const searchIconEl = () => (
    <i className='icon'>
      <img src={searchIcon} width={24} height={24} alt='search-icon' />
    </i>
  );

  useEffect(() => {
    if (isOpen) {
      setSearchInput('');
      const input = ref.current as HTMLInputElement;
      if (input) {
        setTimeout(() => input.focus(), 200);
      }
    }

    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [isOpen]);
  return (
    <div className='mbs'>
      <PrimaryInput
        placeholder='Поиск по коду'
        type='text'
        value={searchInput}
        loading={isLoadingProductName}
        onChange={onChange}
        otherProps={{
          icon: !isLoadingProductName ? searchIconEl : '',
          iconPosition: 'left',
          input: { ref },
        }}
      />
      <div className='results'>
        {searchResults && productByName.length !== 0 ? (
          <ul className='products-list'>
            {productByName.map((product) => (
              <SearchResult key={product.id} product={product} />
            ))}
          </ul>
        ) : (
          <h4 className='not_found'>Ничего не найдено</h4>
        )}
      </div>
    </div>
  );
};

export default MobileSearch;
