import { Button } from 'semantic-ui-react';
import './index.scss';
import React, { FC, ButtonHTMLAttributes } from 'react';
export enum modsType {
  NOTACTIVE = 'notActive',
  ONLYICON = 'onlyIcon',
  MAXWIDTH = 'maxWidth',
  WITHPADDING = 'withPadding',
}
export enum variantType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DELETE = 'delete',
}
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: variantType;
  mods?: modsType | undefined;
  onClick?: () => void;
  children: React.ReactNode;
}
export const PrimaryBtn: FC<Props> = ({
  variant = variantType.PRIMARY,
  mods,
  onClick = () => {
    console.log('press');
  },
  children,
    disabled
}) => {
  return (
    <Button className={`${variant} ` + `${mods}`} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};
