import { instance } from '../index';
import { IProduct, Login } from '../../Types';
// import { useSignIn } from 'react-auth-kit';

export const login = async (params: Login): Promise<any> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const signIn = useSignIn();
  try {
    const res = await instance.post('auth/login', {
      email: params.email.toLowerCase(),
      password: params.password,
      items: params.basket.map((e) => {
        console.log(e);

        return {
          ...(e.type === 'product' ? { productId: e.id } : { optionalEquipmentId: e.id }),
          count: e.quantity,
        };
      }),
    });
    instance.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
    return res;
  } catch (e) {
    return e;
  }

  // return data.data;
  // this.props.signIn();
};

export interface AuthRegisterParams {
  name: string;
  email: string;
  password: string;
  phone: string;
  basket: IProduct[];
}

export const authRegister = async (params: AuthRegisterParams) => {
  try {
    return await instance.post('auth/register', {
      name: params.name,
      email: params.email.toLowerCase(),
      phone: params.phone,
      password: params.password,
      items: params.basket.map((e) => {
        return {
          ...(e.type === 'product' ? { productId: e.id } : { optionalEquipmentId: e.id }),
          count: e.quantity,
        };
      }),
    });
  } catch (e) {
    return e;
  }
};

export interface AuthRegistrationVerification {
  email: string;
  code: string;
}

export const authRegistrationVerification = async (params: AuthRegistrationVerification) => {
  try {
    return await instance.get(`auth/confirm-email?code=${params.code}&email=${params.email.replace('@', '%40')}`);
  } catch (e) {
    return e;
  }
};

export const authRegistrationVerificationCodeRequest = async (email: string) => {
  try {
    return await instance.get(`auth/send-confirm-email?&email=${email.replace('@', '%40')}`);
  } catch (e) {
    return e;
  }
};

export interface AuthResetPasswordNew {
  email: string;
  code: string;
  password: string;
}

export const authResetPasswordNew = async (params: AuthResetPasswordNew) => {
  try {
    return await instance.post(`/auth/reset-password-confirm?code=${params.code}&email=${params.email.replace('@', '%40')}`, {
      password: params.password
    });
  } catch (e) {
    return e;
  }
};

export const authResetPasswordCodeRequest = async (email: string) => {
  try {
    return await instance.post('/auth/reset-password', {
      email
    });
  } catch (e) {
    return e;
  }
};
