import { Modal, ModalProps } from 'semantic-ui-react';
import { FC, ReactNode, SyntheticEvent } from 'react';
import './index.scss';
interface Props {
  onClose: (e: SyntheticEvent, data: ModalProps) => void;
  onOpen: (e: SyntheticEvent, data: ModalProps) => void;
  open: boolean;
  children: ReactNode;
  onMount?: () => void;
  onUnmount?: () => void;
  closeOnDimmerClick?: boolean;
  closeIcon?: boolean | object | ReactNode;
  basic?: boolean;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  className?: string;
}
export const PrimaryModal:FC<Props> = (
    {
        className,
        onClose,
        onMount,
        onOpen,
        open,
        size= 'large',
        closeOnDimmerClick = true,
        onUnmount,
        closeIcon=false,
        basic = true,
        children}
) => {
    return (
        <Modal
            basic={basic}
            closeIcon={closeIcon}
            open={open}
            onMount={onMount}
            onClose={onClose}
            onOpen={onOpen}
            size={size}
            onUnmount={onUnmount}
            closeOnDimmerClick={closeOnDimmerClick}
            className={className}
        >
            <Modal.Content>
                {children}
            </Modal.Content>
        </Modal>
    )
}