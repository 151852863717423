import { instance } from '../index';

export const postOrganization = async (inn: string) => {
  const data = await instance.post('organization', {
    inn,
  });
  return data.data;
};

export const getOrganization = async () => {
  return (await instance.get('organization')).data;
};
