import { instance } from '../index';
import { GetCompatibleDataResponse } from '../../Types';

export const getCompatibleData = async (
  manufacturerId: number | undefined,
): Promise<GetCompatibleDataResponse> => {
  const { data } = await instance.get(`/catalog/products/models`, {
    params: { manufacturer: manufacturerId },
  });
  console.log(manufacturerId, data);

  return data;
};
