import './index.scss';

export const Loader = () =>  { 
    return ( 
        <>
        <div className="gears">
            <div className="gears-container">
                <div className="gear-rotate"></div>
                <div className="gear-rotate-left"></div>
            </div>
        </div>
        </>
    );     
}