import { OptionalEquipment } from '../../../../Shared/Types';
import { FC } from 'react';
import { ItemCardFactory } from '../../../../Entity/ItemCard/Ui/ItemCardFactory/ItemCardFactory';
import { AddToBasket } from '../../../../Features/addToBasket/Ui';
import './index.scss';
import { TextArea } from 'semantic-ui-react';
import { log } from 'util';
import modalSlice from '../../../../Shared/Redux/features/modalSlice';

interface Props {
  models: any[];
}

export const CompatibleModels: FC<Props> = ({ models }) => {
  return (
    <div className='compatible-models'>
      <h3 className='name'>Совместимое оборудование</h3>
      <div className={'models'}>
        {models.map((item) => (
            <div className={'model'}>
                <div className={'title'}>{item.name}</div>
                <div className={'brands'}>
                    {item.models.map((e: any) => (
                        <div>{e.name}</div>
                    ))}
                </div>
            </div>
        ))}
      </div>
    </div>
  );
};
