import { Empty } from '../../../Widgets/Empty/Ui';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { Order } from '../../Order/Ui';
import React, { useEffect, useState } from 'react';
import { getOrders } from '../../../Shared/Api/order';
import closeIcon from '../../../assets/icons/close.svg';
export const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [isEmpty, setEmpty] = useState(null);
  const [reqComplete, setReqComplete] = useState(false);
  const navigate = useNavigate();
  const redirectToCatalog = () => {
    navigate('/catalog');
  };
  const onBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  };

  React.useEffect(() => {
    // @ts-ignore
    ym(93823936, 'hit', 'myOrders', {params:{
        title: 'Мои заказы',
        referer: 'https://innotechmet.ru/myOrders'
      }});
  }, []);

  useEffect(() => {
    if (reqComplete) {
      console.log('complete');
      return;
    }
    async function set() {
      setOrders(await getOrders());
      setReqComplete(true);
    }
    set();
  });
  useEffect(() => {
    setEmpty(orders.length === 0);
  }, [orders]);
  useEffect(() => {
    const footerEl = document.querySelector('.footer') as HTMLElement;
    if (!footerEl) return;
    if (isEmpty) {
      footerEl.style.display = 'none';
    } else {
      footerEl.style.display = 'block';
    }
  }, [isEmpty]);
  return (
    <>
      <div className={`my-order-page ${isEmpty ? 'empty' : ''}`}>
        <div className='wrapper'>
          <div className='title-container'>
            <h3 className={'title'}>Мои заказы</h3>
            <div className='close-icon' onClick={onBack}><img src={closeIcon} alt="" /></div>
          </div>
          {orders.length === 0 ? (
            <Empty
              title={'У вас еще нет заказов'}
              buttonContent={'Посмотреть каталог'}
              onClick={redirectToCatalog}
            />
          ) : (
            orders.map((order: any) => <Order order={order}></Order>)
          )}
        </div>
      </div>
    </>
  );
};
