import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { setProductForParams } from '../../../Shared/Redux/features/product';
import { CatalogItems } from './catalogItems';
import { CatalogFilters, CatalogFiltersMobile } from './catalogFilters';
import { setOptionalEquipment } from '../../../Shared/Redux/features/optionalEquipments';
import './index.scss';
import { Loader } from '../../../Shared/Ui/UIKit/loader';
import { setCompatibleData } from '../../../Shared/Redux/features/compatibleEquipment';
import { modsType, PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import filterIcon from '../../../assets/icons/filter.svg';
import { closeMobileFilters, openMobileFilters } from '../../../Shared/Redux/features/mobileFiltersSlice';
import { Link } from 'react-router-dom';
import bagIcon from '../../../assets/icons/itemCard/bag.svg';

export const CatalogPage = () => {
  const dispatch = useAppDispatch();
  const { isLoadingCatalog } = useAppSelector((state) => state.product);
  const filtersCatalog = useAppSelector((state) => state.filters.filtersCatalog);
  const { manufacturer } = useAppSelector((state) => state.filters.filtersEquipment);
  const filtersMachine = useAppSelector((state) => state.filters.filtersMachine);
  const isOpen = useAppSelector((state) => state.mobileFilters.isOpen);

  React.useEffect(() => {
    // @ts-ignore
    ym(93823936, 'hit', 'catalog', {params:{
        title: 'Католог',
        referer: 'https://innotechmet.ru/catalog'
      }});
  }, []);

  useEffect(() => {
    dispatch(setProductForParams(filtersCatalog));
  }, [filtersCatalog]);

  useEffect(() => {
    dispatch(setCompatibleData(manufacturer));
  }, [manufacturer]);

  useEffect(() => {
    dispatch(setOptionalEquipment(filtersMachine));
  }, [filtersMachine]);

  return (
    <>
      {
        <div className='catalog-page'>
          <div className='wrapper'>
            <div className='title-container'>
              <h3 className='title'>Онлайн-каталог</h3>
              <h3 className='title mobile'>Каталог</h3>
              <PrimaryBtn
                  onClick={() => isOpen ? dispatch(closeMobileFilters()) : dispatch(openMobileFilters())}
              >
                Фильтры
                <img src={filterIcon} alt="" />
              </PrimaryBtn>
            </div>
            <CatalogFiltersMobile />
            <CatalogFilters />
            {isLoadingCatalog ? <Loader /> : <CatalogItems />}
          </div>
        </div>
      }
    </>
  );
};
