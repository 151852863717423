import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
  modals: {
    contactModal: boolean;
    deleteModal: boolean;
    orderModal: boolean;
    orderSuccessModal: boolean;
    orderSuccessModalPhone: string;
    deleteModalCartId: number;
    deleteModalItemId: number;
    deleteModalItemType: string;
  };
}
export enum ModalsName {
  CONTACT = 'contactModal',
  DELETEMODAL = 'deleteModal',
  ORDERMODAL = 'orderModal',
  ORDERSUCCESSMODAL = 'orderSuccessModal',
}

const initialState: ModalState = {
  modals: {
    contactModal: false,
    deleteModal: false,
    orderModal: false,
    orderSuccessModal: false,
    orderSuccessModalPhone: undefined,
    deleteModalCartId: undefined,
    deleteModalItemId: undefined,
    deleteModalItemType: undefined,
  },
};

export const ModalSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    onOpen: (state, action: PayloadAction<ModalsName>) => {
      state.modals[action.payload] = true;
      // state.modals.deleteModalCartId = 123;
    },

    onClose: (state, action: PayloadAction<ModalsName>) => {
      state.modals[action.payload] = false;
      // state.modals.deleteModalCartId = undefined;
    },

    setModalCartItemId: (state, action) => {
      state.modals.deleteModalCartId = action.payload.cartId;
      state.modals.deleteModalItemId = action.payload.itemId;
      state.modals.deleteModalItemType = action.payload.itemType;

      console.log(state.modals.deleteModalCartId);
    },
    setModalPhone: (state, action) => {
      state.modals.orderSuccessModalPhone = action.payload;
    },
  },
});
export const { onOpen, onClose, setModalCartItemId, setModalPhone } = ModalSlice.actions;
export default ModalSlice.reducer;
