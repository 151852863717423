import React, { FC } from 'react';
import { PrimaryModal } from '../../../Shared/Ui/UIKit/modal';
import { modsType, PrimaryBtn, variantType } from '../../../Shared/Ui/UIKit/button';
import './index.scss';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { ModalsName, onClose, onOpen } from '../../../Shared/Redux/features/modalSlice';
import {
  deleteItemFromBasketNoAuth,
  setFullBasket,
} from '../../../Shared/Redux/features/basketSlice';
import { deleteCartItem } from '../../../Shared/Api/basket';
import { useIsAuthenticated } from 'react-auth-kit';
import closeIcon from '../../../../src/assets/icons/modal-close.svg';

export const DeleteModal: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { deleteModal, deleteModalCartId, deleteModalItemId, deleteModalItemType } = useAppSelector(
    (state) => state.modals.modals,
  );
  const icon = React.createElement('img', { src: closeIcon, className: 'btn-close' }, null);
  const dispatch = useAppDispatch();
  const openModal = () => {
    dispatch(onOpen(ModalsName.DELETEMODAL));
  };
  const closeModal = () => {
    dispatch(onClose(ModalsName.DELETEMODAL));
  };
  async function confirm() {
    if (isAuthenticated()) dispatch(setFullBasket(await deleteCartItem(deleteModalCartId)));
    else {
      dispatch(deleteItemFromBasketNoAuth({ id: deleteModalItemId, type: deleteModalItemType }));
    }
  }
  return (
    <PrimaryModal
      open={deleteModal}
      onOpen={openModal}
      onClose={closeModal}
      className='delete-modal modal-border-radius'
      size={'tiny'}
      basic={false}
      closeIcon={icon}
    >
      <h4 className="title">
        Вы уверены, что хотите удалить товар из корзины?
      </h4>
      <div className="buttons">
        <PrimaryBtn
            mods={modsType.MAXWIDTH}
            onClick={() => {
              confirm();
              dispatch(closeModal);
            }}
          >
            Да
          </PrimaryBtn>
          <PrimaryBtn
              variant={variantType.DELETE}
              mods={modsType.MAXWIDTH}
              onClick={() => {
                dispatch(closeModal);
              }}
          >
            Нет
          </PrimaryBtn>
      </div>
    </PrimaryModal>
  );
};
