import { MouseEvent } from 'react';
import { ItemCardFactory } from '../../../../../Entity/ItemCard/Ui/ItemCardFactory/ItemCardFactory';
import { AddToBasket } from '../../../../../Features/addToBasket/Ui';
import { useAppDispatch, useAppSelector } from '../../../../../Shared/Redux/hooks';
import { PaginationProps } from 'semantic-ui-react';
import { changeFiltersCatalog } from '../../../../../Shared/Redux/features/filters';
import { PrimaryPagination } from '../../../../../Shared/Ui/UIKit/pagination';

export const CatalogProduct = () => {
  const { products, meta } = useAppSelector(state => state.product.productData);
  const dispatch = useAppDispatch();
  const onPageChange = (e: MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
    dispatch(changeFiltersCatalog({ name: "page", selectedItem: Number(data.activePage) }));
  };
  return (
    <>
      {
        products
          .map(item => (
            <ItemCardFactory
              product={item}
              features={<AddToBasket type="product" product={item} />}
              key={item.id}
              type="product" />
          ))
      }
      <div className={"pagination_container"}>
        <PrimaryPagination
          activePage={meta.currentPage}
          totalPages={meta.totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
};
