import React, { FC } from 'react';
import { PrimaryModal } from '../../../Shared/Ui/UIKit/modal';
import './index.scss';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { ModalsName, onClose, onOpen } from '../../../Shared/Redux/features/modalSlice';
import closeIcon from '../../../../src/assets/icons/modal-close.svg';
import { useIsAuthenticated } from 'react-auth-kit';

type TOrderSuccessModalProps = {
  onClose: () => void;
}
export const OrderSuccessModal: FC<TOrderSuccessModalProps> = ({
    onClose: onOwnClose
}) => {
  const icon = React.createElement('img', { src: closeIcon, className: 'btn-close' }, null);
  const isAuthenticated = useIsAuthenticated();
  const { basket } = useAppSelector((state) => state.basket);
  const { isOpen, titleComponent } = useAppSelector((state) => state.sideBar);
  const { orderSuccessModal, orderSuccessModalPhone } = useAppSelector(
    (state) => state.modals.modals,
  );
  const dispatch = useAppDispatch();
  const openModal = () => {
    dispatch(onOpen(ModalsName.ORDERSUCCESSMODAL));
  };
  const closeModal = () => {
    dispatch(onClose(ModalsName.ORDERSUCCESSMODAL));
    onOwnClose();
  };
  return (
    <PrimaryModal
      open={orderSuccessModal}
      onOpen={openModal}
      onClose={closeModal}
      size={'tiny'}
      basic={false}
      className={'order-success-modal modal-border-radius'}
      closeIcon={icon}
    >
      <div className='modal-content'>
        <h4 className='title'>
          Заказ успешно сформирован
        </h4>
        <p className='description'>
          {'Наш специалист свяжется с вами по указанному номеру или e-mail и уточнит детали заказа.'}
        </p>
      </div>
    </PrimaryModal>
  );
};
