import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';
import { Provider } from 'react-redux';
import store from './Shared/Redux/store';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'react-auth-kit';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider
    authType={'localstorage'}
    authName={'_auth'}
    // cookieDomain={window.location.hostname}
    // cookieSecure={window.location.protocol === 'https:'}
  >
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </AuthProvider>,
);
