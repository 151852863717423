import { FC, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { setProductById } from '../../../Shared/Redux/features/product';
import { Headline } from './headline';
import { Characterisctic } from './characterisctic';
import { VisualModel } from './visualModel';
import './index.scss';
import { ProductOptionalEquipment } from './optionalEquipment';
import LoadingBar from 'react-top-loading-bar';
import { CompatibleModels } from './compatibleModels';

export const ProductPage: FC = () => {
  const loadingRef = useRef(null);
  const { pathname } = useLocation();
  const { isLoadingProduct, productById } = useAppSelector((state) => state.product);
  const { id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadingRef.current.continuousStart();
    dispatch(setProductById(id)).finally(() => {
      loadingRef.current.complete();
    });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <LoadingBar color='white' ref={loadingRef} />

      {isLoadingProduct ? (
        ''
      ) : (
        <div className={'product-page'}>
          <div className='wrapper'>
            <div className={'product-page__content'}>
              <div className={'left-side'}>
                <img className='product_image' src={process.env.REACT_APP_ASSETS_URL + '/' + productById.vendor_code + '-PNG_SKETCH.png'} alt='' />
                {//@ts-ignore
                  productById.type_id.type !== 'Стационарная' && productById.category_id.category !== 'Стационарные VDI'
                    && <Characterisctic product={productById} />
                }
                <VisualModel product={productById} />
                {
                  productById.category_id.category !== 'Стационарные VDI' &&
                    <>
                      <ProductOptionalEquipment optional={productById.equipment} />
                      <CompatibleModels models={productById.brands} />
                    </>
                }
              </div>
              <div className={'right-side'}>
                <Headline product={productById} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
