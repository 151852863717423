import pencilIcon from '../../../assets/icons/contactPage/pencil-ruler.svg';
import factoryIcon from '../../../assets/icons/contactPage/factory.svg';
import emailIcon from '../../../assets/icons/contactPage/email.svg';
import phoneIcon from '../../../assets/icons/contactPage/phone.svg';
import telegramIcon from '../../../assets/icons/contactPage/telegram.svg';
import React, { useEffect, useState } from 'react';

import './index.scss';
import { appOptions } from '../../../Shared/options';

export const ContactPage = () => {
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(600);

  React.useEffect(() => {
    // @ts-ignore
    ym(93823936, 'hit', 'contact', {params:{
        title: 'Контакты',
        referer: 'https://innotechmet.ru/contact'
      }});
  }, []);

  useEffect(() => {
    const onResize = () => {
      const width = window.innerWidth;
      if (width < 1080) {
        let padding = 80;
        if (width < 600) {
          padding = 48;
        }
        setWidth(width - padding);
        setHeight(410);
      } else {
        setWidth(400);
        setHeight(600);
      }
    }

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  return (
    <section className="contact-page">
      <div className="wrapper">
        <h2 className="contact-page__title">Контакты <br />ООО ПО «ИнноТехМет»</h2>
        <div className="page_container">
          <div className="contact-page__information">
            <div className={'group mobile'}>
              <div className="contact">
                <div className="icon-container">
                  <img src={emailIcon} alt="" />
                </div>
                <div className="contact_container">
                  <h3 className="title">E-mail</h3>
                  <div className="contact_container__content">
                    <h4 className="description">{<div dangerouslySetInnerHTML={{ __html: appOptions.contacts.emailUrl }}></div>}</h4>
                  </div>
                </div>
              </div>
              <div className="contact">
                <div className="icon-container">
                  <img src={phoneIcon} alt="" />
                </div>
                <div className="contact_container">
                  <h3 className="title">Телефон/факс:</h3>
                  <div className="contact_container__content">
                    <h4 className="description">{<span dangerouslySetInnerHTML={{ __html: appOptions.contacts.phoneUrl }} />} доб 201</h4>
                  </div>
                </div>
              </div>
              {/* <div className="contact">
                <div className="icon-container">
                  <img src={telegramIcon} alt="" />
                </div>
                <div className="contact_container">
                  <h3 className="title">Telegram</h3>
                  <div className="contact_container__content">
                    <h4 className="description">
                      <a href={appOptions.contacts.telegramBotUrl} target={'_blank'}>ITM_catalog_bot.t.me</a>
                    </h4>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="contact">
              <div className="icon-container">
                <img src={pencilIcon} alt="" />
              </div>
              <div className="contact_container">
                <h3 className="title">Конструкторское бюро</h3>
                <div className="contact_container__content">
                  <h3 className="title">Адрес:</h3>
                  <h4 className="description">
                    121205, г. Москва, ул. Большой бульвар, д. 42, стр. 1, Инновационный
                    центр Сколково
                  </h4>
                </div>
              </div>
            </div>
            <div className="contact">
              <div className="icon-container">
                <img src={factoryIcon} alt="" />
              </div>
              <div className="contact_container">
                <h3 className="title">Производственная площадка</h3>
                <div className="contact_container__content">
                  <h3 className="title">Адрес:</h3>
                  <h4 className="description">
                    249722, Калужская область, г. Козельск, ул. Чкалова, д. 79
                  </h4>
                </div>
                <div className="contact_container__content">
                  <h3 className="title">ИНН:</h3>
                  <h4 className="description">
                    4009002680
                  </h4>
                </div>
              </div>
            </div>
            <div className={'group'}>
              <div className="contact">
                <div className="icon-container">
                  <img src={phoneIcon} alt="" />
                </div>
                <div className="contact_container">
                  <h3 className="title">Телефон/факс:</h3>
                  <div className="contact_container__content">
                    <h4 className="description">{<span dangerouslySetInnerHTML={{ __html: appOptions.contacts.phoneUrl }} />} доб 201</h4>
                  </div>
                </div>
              </div>
              <div className="contact">
                <div className="icon-container">
                  <img src={emailIcon} alt="" />
                </div>
                <div className="contact_container">
                  <h3 className="title">E-mail</h3>
                  <div className="contact_container__content">
                    <h4 className="description">{<div dangerouslySetInnerHTML={{ __html: appOptions.contacts.emailUrl }}></div>}</h4>
                  </div>
                </div>
              </div>
              {/* <div className="contact">
                <div className="icon-container">
                  <img src={telegramIcon} alt="" />
                </div>
                <div className="contact_container">
                  <h3 className="title">Telegram</h3>
                  <div className="contact_container__content">
                    <h4 className="description">
                      <a href={appOptions.contacts.telegramBotUrl} target={'_blank'}>ITM_catalog_bot.t.me</a>
                    </h4>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="map">
            <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A8ffaf46335d5233a66017246f76bcc9226179bc00896bde91c28b44927f0d440&amp;source=constructor"
                width={width} height={height} frameBorder="0"></iframe>
            {/* <div id="map" style="width: 600px; height: 400px"></div> */}
          </div>
        </div>
      </div>
    </section>
  );
};
