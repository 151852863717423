
import { useAppSelector} from "../../../../Shared/Redux/hooks";
import {SelectFilterGroup}  from '../../../../Shared/Redux/features/filters';
import { CatalogMachines } from "./catalogMachines";
import { CatalogProduct } from "./catalogProduct";
export const CatalogItems = () => {
    const currentFilterGroup = useAppSelector(state => state.filters.selectFilterGroup);
    
    switch (currentFilterGroup) { 
        case SelectFilterGroup.MACHINES : 
            return <CatalogMachines />; 
        default: return <CatalogProduct />; 
    }
    
}