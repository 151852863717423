import { PrimaryInput } from '../../../Shared/Ui/UIKit/input';
import { modsType, PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import './index.scss';
import { FC } from 'react';
import { useAuthUser } from 'react-auth-kit';
export const ProfilePage: FC = () => {
  const auth = useAuthUser();
  console.log(auth());
  return (
    <>
      <form className={'profile_form'}>
        <div className={'fields'}>
          <div className={'field_container'}>
            <label htmlFor=''>Имя</label>
            <PrimaryInput placeholder={'Иван '} value={auth().name} />
          </div>
          {/*<div className={'field_container'}>*/}
          {/*  <label htmlFor=''>Фамилия</label>*/}
          {/*  <PrimaryInput placeholder={'Петров'} value={auth().surname} />*/}
          {/*</div>*/}
          <div className={'field_container'}>
            <label htmlFor=''>email</label>
            <PrimaryInput disabled placeholder={'some-email@com'} value={auth().email} />
          </div>
          <div className={'field_container'}>
            <label htmlFor=''>Телефон</label>
            <PrimaryInput placeholder={'+7 916 037 33 77'} value={auth().phone} />
          </div>
        </div>
        <PrimaryBtn mods={modsType.MAXWIDTH}>Подтвердить изменения</PrimaryBtn>
      </form>
    </>
  );
};
