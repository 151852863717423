import { FC, ReactNode } from 'react';
import { Card } from '../../../Shared/Ui/UIKit/card';
import './index.scss';
import { numberWithSpaces } from '../../../Shared/Helpers/functions/numbersWithSpaces';
import { OptionalEquipment, Product } from '../../../Shared/Types';

interface Props {
  product: Product;
  features: ReactNode;
  isOptional?: boolean;
}
interface ItemCardPros extends Props {
  list: ReactNode;
  imgNode: ReactNode;
}
const Features: FC<Props> = ({ features, product, isOptional }) => (
    <>
        <div className='price__container'>
            <h3 className='price'>{numberWithSpaces(product.price)} ₽</h3>
        </div>
        <div
            className={`
                box
                ${product?.deliveryTime ? 'with-delivery' : ''}
                ${isOptional ? 'with-about' : ''}
            `}
        >
            {!!product?.deliveryTime && !isOptional && (
                <div className='delivery'>
                    <br></br>
                    <span className='title'>Срок поставки: </span>
                    <span className='description'>≈ {product?.deliveryTime} мес.</span>
                </div>
            )}
            {isOptional &&
                <div className='about'>
                    <span className='title'>Описание: </span>
                    {//@ts-ignore
                        <span className="description">{product.description || '-'}</span>}
                </div>
            }
            {/*{!product.isOrder && <div className='remainder'>Остаток на складе: {product.amount}</div>}*/}
            {features}
        </div>
    </>
);

export const ItemCard: FC<ItemCardPros> = ({ product, features, list, imgNode }) => {
  return (
    <Card
      itemNode={list}
      featureNode={<Features features={features} product={product} isOptional={product.type === 'optionalEquipment'} />}
      imgNode={imgNode}
      disableFavorites={true}
    ></Card>
  );
};
