import minusIcon from '../../../assets/icons/itemCard/Minus.svg';
import plusIcon from '../../../assets/icons/itemCard/Plus.svg';
import bagIcon from '../../../assets/icons/itemCard/bag.svg';
import { modsType, PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { FC, useEffect, useRef } from 'react';
import { OptionalEquipment, Product } from '../../../Shared/Types';
import {
  addCountItem,
  addToBasketNoAuth,
  changeBasketItemCountNoAuth,
  removeCountItem,
  setBasket,
  setDeleteId,
  setFullBasket,
} from '../../../Shared/Redux/features/basketSlice';
import './index.scss';
import { ModalsName, onOpen, setModalCartItemId } from '../../../Shared/Redux/features/modalSlice';
import deleteIcon from '../../../assets/icons/card-delete.svg';
import { addToBasket, changeBasketItemCount, deleteCartItem } from '../../../Shared/Api/basket';
import LoadingBar from 'react-top-loading-bar';
import { useIsAuthenticated } from 'react-auth-kit';
import { log } from 'util';
import { Link } from 'react-router-dom';

interface Props {
  product: Product | OptionalEquipment;
  type: string;
}

export const AddToBasket: FC<Props> = ({ product, type }) => {
  const isAuthenticated = useIsAuthenticated();
  const loadingRef = useRef(null);
  const { basket } = useAppSelector((state) => state.basket);
  const dispatch = useAppDispatch();
  const basketItem = basket.find((item) => item.id === product.id);

  async function addToBasketApi(item: any) {
    loadingRef.current.continuousStart();
    if (isAuthenticated()) dispatch(setFullBasket(await addToBasket(item.id, item.type)));
    else {
      dispatch(addToBasketNoAuth(item));
    }
    loadingRef.current.complete();
  }

  async function setCount(item: any, delta: number) {
    // console.log(item);
    loadingRef.current.continuousStart();
    if (isAuthenticated())
      dispatch(setFullBasket(await changeBasketItemCount(item.cartId, item.quantity + delta)));
    else {
      dispatch(
        changeBasketItemCountNoAuth({
          id: item.id,
          quantity: item.quantity + delta,
          type: item.type,
        }),
      );
    }
    loadingRef.current.complete();
  }

  return (
    <>
      <LoadingBar color='#184788' ref={loadingRef} />
      {basket.some((item) => item.id === product.id && item.type === product.type) ? (
        <div className='counter'>
          <div
            className='btn_delete'
            onClick={() => {
              // dispatch(setDeleteId(basketItem.id));
              dispatch(
                setModalCartItemId({
                  cartId: (basketItem as any).cartId,
                  itemId: basketItem.id,
                  itemType: basketItem.type,
                }),
              );
              dispatch(onOpen(ModalsName.DELETEMODAL));
            }}
          >
            <img src={deleteIcon} alt='' />
            <h3 className='title'>Удалить</h3>
          </div>
          <div className='right'>
            <div className='counter__content'>
              <div
                  className='counter_btn'
                  onClick={() => {
                    console.log(basketItem?.quantity);
                    if (basketItem?.quantity === 1) {
                      // dispatch(setDeleteId(basketItem.id));
                      dispatch(
                          setModalCartItemId({
                            cartId: (basketItem as any).cartId,
                            itemId: basketItem.id,
                            itemType: basketItem.type,
                          }),
                      );
                      dispatch(onOpen(ModalsName.DELETEMODAL));
                    } else {
                      // dispatch(removeCountItem(basketItem.id));
                      setCount(basketItem, -1);
                    }
                  }}
              >
                <img src={minusIcon} alt='' />
              </div>
              <h4 className='count'>{basketItem?.quantity}</h4>
              <div
                  className='counter_btn'
                  onClick={() => {
                    setCount(basketItem, 1);
                    // dispatch(addCountItem(basketItem.id));
                  }}
              >
                <img src={plusIcon} alt='' />
              </div>
            </div>
            <div>
              <Link to={'/basket'}>
                <PrimaryBtn mods={modsType.ONLYICON}>
                  <img src={bagIcon} alt='' />
                </PrimaryBtn>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <PrimaryBtn onClick={() => addToBasketApi({ ...product, type, quantity: 1 })}>
          В корзину
        </PrimaryBtn>
      )}
    </>
  );
};
