import { modsType, PrimaryBtn, variantType } from '../../../Shared/Ui/UIKit/button';
import phoneIcon from '../../../assets/icons/phone-call.svg';
import visioner from '../../../assets/icons/visioner.svg';
import videoDesktop from '../../../assets/video/rootDesktop.mp4';
import desktopPoster from '../../../assets/video/posterDesktop.png';
import videoMobile from '../../../assets/video/rootMobile.mp4';
import mobilePoster from '../../../assets/video/posterMobile.png';
import { ContactModal } from '../../../Entity/ContactModal/Ui';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { ModalsName, onOpen } from '../../../Shared/Redux/features/modalSlice';
import { useAppDispatch } from '../../../Shared/Redux/hooks';
import visionerIcon from '../../../assets/icons/visioners-icon-light.svg';
import visioners from '../../../assets/icons/visioners-light.svg';
import { Loader } from '../../../Shared/Ui/UIKit/loader';


export const Root = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    // @ts-ignore
    ym(93823936, 'hit', 'main', {params:{
        title: 'Главная',
        referer: 'https://innotechmet.ru/'
      }});
  }, []);

  return (
    <>
      {loading && <Loader />}
      <section className='headline'>
        <div className='headline-wrapper'>
          <div className='headline-content'>
            <div className='title-container'>
              <h2 className='title'>
                Оправки для <br /> станков с ЧПУ
              </h2>
              <h3 className='description'>
                Высококачественная продукция,
                <br /> сделанная в России.
              </h3>
            </div>
            <div className='btns-container'>
              <div className='container first'>
                <PrimaryBtn
                  mods={modsType.MAXWIDTH && modsType.WITHPADDING}
                  onClick={() => {
                    navigate('/catalog');
                  }}
                >
                  Посмотреть каталог
                </PrimaryBtn>
              </div>
              <div className='container second'>
                <PrimaryBtn
                  variant={variantType.SECONDARY}
                  mods={modsType.WITHPADDING}
                  onClick={() => {
                    dispatch(onOpen(ModalsName.CONTACT));
                  }}
                >
                  <img src={phoneIcon} alt='' />
                </PrimaryBtn>
              </div>
            </div>
            <figure className='visioner_logo'>
              {/* <img src={visioner} alt='' className='union' />
              <a href={'https://visioners.ru/'} target={'_blank'}>
                <div className="visioner_logo__text">
                  <img src={visionerIcon} alt="" />
                  <h3>сайт разработан</h3>
                  <img src={visioners} alt="" />
                </div>
              </a> */}
            </figure>
          </div>
          {width >= 601 ? (
              <video
                  className='video desktop'
                  autoPlay
                  muted
                  loop
                  id='myVideo'
                  poster={desktopPoster}
                  playsInline
                  onLoadedData={() => setLoading(false)}
              >
                <source src={videoDesktop} type='video/mp4' />
              </video>
          )
              :
              <video
                  className='video mobile'
                  autoPlay
                  muted
                  loop
                  id='myVideo'
                  poster={mobilePoster}
                  playsInline
                  onLoadedData={() => setLoading(false)}
              >
                <source src={videoMobile} type='video/mp4' />
              </video>
          }
        </div>
      </section>
      <ContactModal />
    </>
  );
};
