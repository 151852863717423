function wrapLink(proto: string, url: string) {
  return `<a href="${proto}:${url}">${url}</a>`;
}

export const appOptions = {
  contacts: {
    phone: "8 800 770-75-55",
    get phoneUrl() {
      return wrapLink("tel", this.phone);
    },
    telegramBotUrl: "https://t.me/ITM_catalog_bot",
    email: "office@po-itm.ru",
    get emailUrl() {
      return wrapLink("mailto", this.email);
    },
    addressDesignDepartment: "121205, г. Москва, ул. Большой бульвар 42с1, Инновационный центр Сколково",
    addressManufactureDepartment: "249722, Калужская область, г. Козельск, ул. Чкалова 79"
  }
};
