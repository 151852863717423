import React, { useState } from 'react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './index.scss';
import company from '../../../assets/video/company.mp4';
//@ts-ignore
import certificate from '../../../assets/pdf/certificate.pdf';
//@ts-ignore
import manufacture from '../../../assets/pdf/manufacture.pdf';
import { Loader } from '../../../Shared/Ui/UIKit/loader';

export const CompanyPage = () => {
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    // @ts-ignore
    ym(93823936, 'hit', 'company', {params:{
        title: 'О компании',
        referer: 'https://innotechmet.ru/company'
      }});
  }, []);

  return (
    <>
      {loading && <Loader />}
      <section className={'company-page'} onLoad={() => alert('hi')}>
        <video
            className='video desktop'
            autoPlay
            muted
            loop
            id='myVideo'
            playsInline
            onLoadedData={() => setLoading(false)}
        >
          <source src={company} type='video/mp4' />
        </video>
        <div className={'about'}>
          <div className={'column'}>
            <h6 className={'title'}>Команда</h6>
            <p className={'text'}>
              150 сотрудников,<br />
              12 технологов-программистов,<br />
              5 ведущих конструкторов-разработчиков<br />
            </p>
          </div>
          <div className={'column'}>
            <h6 className={'title'}>Оборудование</h6>
            <p className={'text'}>
              40 прецизионных обрабатывающих центров с ЧПУ производства Matsuura, Wele, Biglia, Okuma, Studer, Techinica, Sodick, Bmicro, контрольно-измерительные машины Carl Zeiss, Nikon
            </p>
          </div>
          <div className={'column'}>
            <h6 className={'title'}>Сертификация</h6>
            <p className={'text'}>
              <a href={manufacture} download>
                Заключение о подтверждении производства промышленной продукции на территории Российской Федерации по 719 постановлению №46750/05 от 07.06.2021 г.
              </a>
              <a href={certificate} download>
                Сертификат соответствия системы менеджмента качества<br />
                ГОСТ Р ИСО 9001-2015, ГОСТ РВ 0015-002-2020 от 18.04.2023 г.
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

