import { FilterCatalog, OptionalEquipment, Product } from '../../Types/index';
import { instance } from '../index';

export const getParamsProduct = async (params: FilterCatalog): Promise<any> => {
  // console.log(params);
  // console.log(process.env)
  const data = await instance.get(`catalog/products`, {
    params: {
      page: params.page,
      category: params.category,
      type: params.type,
      interfaceTool: params.interface_tool,
      gear: params.gear_ratio,
    },
  });
  const parsedData = data.data.items.data.map((e: any) => {
    return getProductFromResponse(e, true);
  });
  const parsedFilters = {
    availableCategories: data.data.filters.categories.map((e: any) => {
      return {
        id: e.category.id,
        category: e.category.category,
      };
    }),
    availableTypes: data.data.filters.types.map((e: any) => {
      return {
        id: e.type.id,
        type: e.type.type,
      };
    }),
    availableInterfaces: data.data.filters.tools.map((e: any) => {
      return {
        id: e.interfaceTool.id,
        interface_tool: e.interfaceTool.interfaceTool,
      };
    }),
    availableGearRatios: data.data.filters.ratios.map((e: any) => {
      return {
        id: e.gearRatio.id,
        gear_ratio: e.gearRatio.gearRatio,
      };
    }),
  };
  const parsedMeta = {
    currentPage: data.data.items.meta.currentPage,
    totalPages: data.data.items.meta.lastPage,
  };
  const transformData: Product[] = parsedData.sort((a: Product, b: Product) => {
    if (a.vendor_code.length === b.vendor_code.length) {
      return 0;
    }
    const aArr = a.vendor_code.split('.'),
        bArr = b.vendor_code.split('.');
    for (let i = 0; i < Math.min(aArr.length, bArr.length); i++) {
      if (parseInt(aArr[i]) < parseInt(bArr[i])) {
        return -1;
      }
      if (parseInt(aArr[i]) > parseInt(bArr[i])) {
        return 1;
      }
    }
    if (aArr.length < bArr.length) {
      return -1;
    }
    if (aArr.length > bArr.length) {
      return 1;
    }
    return 0;
  });

  return { products: transformData, filters: parsedFilters, meta: parsedMeta };
};

export const getProductById = async (id: string): Promise<Product> => {
  const { data } = await instance.get(`catalog/product/${id}`);
  return getProductFromResponse(data, false);
  // return data
};

export const getProductByNameOrVendoreCode = async (name: string): Promise<Product[]> => {
  const { data } = await instance.get<{ items: { data: Product[] } }>(`catalog/products?search=${name}`);

  const parsedData = data.items.data.map((e: any) => {
    return getProductFromResponse(e, true);
  });

  const transformData = parsedData.sort((a: any, b: any) => {
    if (a.vendor_code === b.vendor_code) {
      return 0;
    }
    const aArr = a.vendor_code.split('.'),
        bArr = b.vendor_code.split('.');
    for (let i = 0; i < Math.min(aArr.length, bArr.length); i++) {
      if (parseInt(aArr[i]) < parseInt(bArr[i])) {
        return -1;
      }
      if (parseInt(aArr[i]) > parseInt(bArr[i])) {
        return 1;
      }
    }
    if (aArr.length < bArr.length) {
      return -1;
    }
    if (aArr.length > bArr.length) {
      return 1;
    }
    return 0;
  });

  return transformData;
};

function getProductFromResponse(e: any, disableEquipments = true) {
  return {
    id: e.id,
    vendor_code: e.listId,
    category_id: e.category,
    type_id: e.type,
    price: e.price + '',
    amount: e.amount,
    quantity: e.amount,
    name: e.name,
    interface_tool_id: {
      id: e.interfaceTool.id,
      interface_tool: e.interfaceTool.interfaceTool,
    },
    internal_coolant: e.internalCoolant,
    weight: e.weight,
    gear_ratio_id: {
      id: e.gearRatio?.id,
      gear_ratio: e.gearRatio?.gearRatio,
    },
    torque_max: e.torqueMax,
    speed_max: e.speedMax,
    power_max: e.powerMax,
    pngIzoUrl: getPngIzoUrl(e),
    equipment: disableEquipments
        ? ([] as OptionalEquipment[])
        : mapEquipments(e.optionalEquipments),
    type: 'product',
    deliveryTime: e.deliveryTime,
    brands: e.brands,
    // equipment:
  };
}

function getPngIzoUrl(o: any) {
  return o.productFiles.find((e: any) => {
    return e.type === 'PNG_IZO';
  })?.file.s3Url;
}

function mapEquipments(items: any) {
  return items.map((e: any) => {
    return {
      id: e.id,
      vendor_code: e.listId,
      category_id: {
        id: e.equipmentCategory.id,
        category: e.equipmentCategory.category,
      },
      type_id: {
        id: e.equipmentType.id,
        type: e.equipmentType.type,
      },
      price: e.price.toString(),
      amount: e.amount.toString(),
      quantity: e.amount.toString(),
      description: e.description,
      type: 'optionalEquipment',
    };
  });
}
