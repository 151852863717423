import profileIcon from '../../../assets/icons/ProfileIcon.svg';
import clientDataIcon from '../../../assets/icons/ClientDataIcon.svg';
import { PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import './index.scss';
import { useAppDispatch } from '../../../Shared/Redux/hooks';
import { closeSideBar, SideBarScreen, switchBeetwenComponent } from '../../../Shared/Redux/features/sideBarSlice';
import { useNavigate } from 'react-router-dom';
import { useSignOut } from 'react-auth-kit';
import ordersIcon from '../../../assets/icons/orders.svg';
import { setBasket } from '../../../Shared/Redux/features/basketSlice';

export const PersonalArea = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profilePage = () => {
    dispatch(switchBeetwenComponent(SideBarScreen.PROFILE));
  };

  const clientDataPage = () => {
    dispatch(switchBeetwenComponent(SideBarScreen.CLIENTDATA));
  };

  const authPage = () => {
    dispatch(switchBeetwenComponent(SideBarScreen.AUTH));
  };

  const signOut = useSignOut();

  const userLogout = () => {
    dispatch(setBasket([]));
    signOut();
    authPage();
  };

  const favoritePage = () => {
    navigate('/favorite');
    dispatch(closeSideBar());
  };
  const myOrdersPage = () => {
    navigate('/myOrders');
    dispatch(closeSideBar());
  };
  return (
    <>
      <div className='navigation_menu'>
        <ul className='nivagation_list'>
          <li className='navigation' onClick={profilePage}>
            <img src={profileIcon} alt='' />
            Профиль
          </li>
          <li className='navigation' onClick={clientDataPage}>
            <img src={clientDataIcon} alt='' />
            Клиентские данные
          </li>
          <li className="navigation" onClick={myOrdersPage}>
            <img src={ordersIcon} alt="" />
            Мои заказы
          </li>
          {/*<li className="navigation" onClick={favoritePage}>*/}
          {/*  <img src={favoriteDark} alt="" />*/}
          {/*  Избранное*/}
          {/*</li>*/}
        </ul>
        <PrimaryBtn onClick={userLogout}>Выйти</PrimaryBtn>
      </div>
    </>
  );
};
