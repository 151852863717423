import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterCatalog } from '../../Types/index';

export enum SelectFilterGroup {
  CATEGORY = 'category',
  EQUIPMENT = 'equipment',
  MACHINES = 'machines',
}
interface Props<T> {
  name: keyof T;
  selectedItem: number;
}
const filtersCatalog: FilterCatalog = {
  category: undefined,
  type: undefined,
  interface_tool: undefined,
  gear_ratio: undefined,
  page: 1,
};

interface FilterEquipment {
  manufacturer: number | undefined;
  model: number | undefined;
}
const filtersEquipment: FilterEquipment = {
  manufacturer: undefined,
  model: undefined,
};
export interface FiltersMachine {
  category: number | undefined;
  type: number | undefined;
  page: number;
}
const filtersMachine: FiltersMachine = {
  category: undefined,
  type: undefined,
  page: 1,
};

interface FiltersInitialState {
  filtersCatalog: FilterCatalog;
  filtersEquipment: FilterEquipment;
  filtersMachine: FiltersMachine;
  selectFilterGroup: SelectFilterGroup;
}

const initialState: FiltersInitialState = {
  filtersCatalog,
  filtersEquipment,
  filtersMachine,
  selectFilterGroup: SelectFilterGroup.CATEGORY,
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    changeFiltersCatalog: (state, action: PayloadAction<Props<FilterCatalog>>) => {
      state.filtersCatalog[action.payload.name] = action.payload.selectedItem;
      // console.log(action.payload)
      if (action.payload.name === 'category') {
        console.log('is category');
        state.filtersCatalog.type = undefined;
        state.filtersCatalog.interface_tool = undefined;
        state.filtersCatalog.gear_ratio = undefined;
      } else if (action.payload.name === 'type') {
        state.filtersCatalog.interface_tool = undefined;
        state.filtersCatalog.gear_ratio = undefined;
      } else if (action.payload.name === 'interface_tool') {
        state.filtersCatalog.gear_ratio = undefined;
      }
    },
    changeFiltersEquipment: (state, action: PayloadAction<Props<FilterEquipment>>) => {
      state.filtersEquipment[action.payload.name] = action.payload.selectedItem;
      // state.filtersEquipment.model = undefined;
      state.filtersCatalog.type = undefined;
      state.filtersCatalog.interface_tool = undefined;
    },
    changeFiltersMachine: (state, action: PayloadAction<Props<FiltersMachine>>) => {
      if (action.payload.name === 'category') state.filtersMachine.type = undefined;
      state.filtersMachine[action.payload.name] = action.payload.selectedItem;
    },
    changeFilterGroup: (state, action) => {
      state.filtersCatalog = filtersCatalog;
      state.filtersEquipment = filtersEquipment;
      state.filtersMachine = filtersMachine;
      state.selectFilterGroup = action.payload;
    },
    resetData: (state) => {
      state = initialState;
    },
  },
});
export const {
  resetData,
  changeFiltersCatalog,
  changeFiltersEquipment,
  changeFiltersMachine,
  changeFilterGroup,
} = filtersSlice.actions;
export default filtersSlice.reducer;
