import { Link } from 'react-router-dom';
import './index.scss';
import { FC } from 'react';
import { numberWithSpaces } from '../../../Shared/Helpers/functions/numbersWithSpaces';

interface Props {
  order: {
    id: number;
    companyName?: string;
    trackNumber?: string;
    price: number;
    status: string;
    createdAt: Date;
  };
}

export const Order: FC<Props> = ({ order }) => {
  const date = new Date(order.createdAt);
  return (
    <>
      <div className='info'>
        <div className='order-card'>
          <div className='order-container'>
            <Link to={`/order/${order.id}`}>
              <h3>
                Заказ №
                {order.id +
                  ' от ' +
                  ('0' + date.getDate()).slice(-2) +
                  '.' +
                  ('0' + date.getMonth()).slice(-2) +
                  '.' +
                  date.getFullYear() +
                  ' г.'}
              </h3>
            </Link>
            <div className={'about'}>
              <p>Номер счета: {order.id}</p>
              <p>Транспортная компания: {order.companyName ? order.companyName : '...'}</p>
              <p>Трек номер транспортной компании: {order.trackNumber ? order.trackNumber : '...'}</p>
            </div>
            <h2>{numberWithSpaces(order.price.toString())} ₽</h2>
          </div>

          {/*<div className='status-container'></div>*/}
          <div className={'status-container'}>
            <p className='title'>Статус</p>
            {order.status === 'CHECK' || order.status === 'PAY' || order.status === 'DELIVERY' ? (
              <div
                className={
                  'status ' +
                  (order.status === 'CHECK'
                    ? 'invoice-request'
                    : order.status === 'PAY'
                    ? 'payment'
                    : 'delivery')
                }
              >
                <div className='status-content'>
                  <div className='img' />
                  <p>Запрос счета</p>
                </div>
                <div className='status-content'>
                  <div className='img' />
                  <p>Оплата</p>
                </div>
                <div className={'status-content'}>
                  <div className='img' />
                  <p>Доставка</p>
                </div>
              </div>
            ) : (
              <div>
                {order.status === 'COMPLETED' ? (
                  <div className='status success'>
                    <div className='status-content'>
                      <div className='img' />
                      <p>Выполнен</p>
                    </div>
                  </div>
                ) : order.status === 'CANCELED' ? (
                  <div className='status canceled'>
                    <div className='status-content'>
                      <div className='img' />
                      <p>Отменен</p>
                    </div>
                  </div>
                ) : (
                  <div className='status invoice-request'>
                    <div className='status-content'>
                      <div className='img' />
                      <p>В процессе</p>
                    </div>
                    <div></div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
