import { Dropdown } from 'semantic-ui-react';
import React, { FC, ReactNode, MouseEvent } from 'react';
import './index.scss';
import downArrow from '../../../../assets/icons/down-arrow.svg';
import downArrowDisabled from '../../../../assets/icons/down-arrow-disabled.svg';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

interface Props {
  optionsList?: { key: number; text: string; value: string }[];
  onClick?: (e: MouseEvent<HTMLDivElement>, data: object) => void;
  otherProps?: DropdownProps;
  disabled?: boolean;
  optionalIcon?: ReactNode;
  placeholder?: string;
  value?: any;
}

const defaultList: { key: number; text: string; value: string }[] = [
  {
    key: 0,
    text: 'default item',
    value: 'default item',
  },
];
const defaultIcon = React.createElement('img', { src: downArrow, className: 'custom_arrow' }, null);
const disabledIcon = React.createElement('img', { src: downArrowDisabled, className: 'custom_arrow disabled' }, null);
export const PrimaryDropdown: FC<Props> = ({
  optionsList = defaultList,
  disabled = false,
  otherProps,
  onClick = (e, data) => {
    console.log(data);
  },
  optionalIcon = <>{defaultIcon}{disabledIcon}</>,
  placeholder = 'select',
  value = null,
}) => {
  return (
    <Dropdown
      className='custom__drop'
      fluid
      selection
      options={optionsList}
      placeholder={placeholder}
      icon={optionalIcon}
      openOnFocus={false}
      selectOnBlur={false}
      onChange={onClick}
      disabled={disabled}
      value={value}
      {...otherProps}
    />
  );
};
