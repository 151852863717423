import { modsType, PrimaryBtn, variantType } from '../../../../Shared/Ui/UIKit/button';
import downloadIcon from '../../../../assets/icons/download-arrow.svg';
import { Divider } from 'semantic-ui-react';
import { numberWithSpaces } from '../../../../Shared/Helpers/functions/numbersWithSpaces';
import { FC } from 'react';
import { Product } from '../../../../Shared/Types';
import './index.scss';
import { setFullBasket } from '../../../../Shared/Redux/features/basketSlice';
import { addToBasket } from '../../../../Shared/Api/basket';
import { useAppDispatch } from '../../../../Shared/Redux/hooks';
import { type } from 'os';
import { AddToBasket } from '../../../../Features/addToBasket/Ui';

interface Props {
  product: Product;
}
export const Headline: FC<Props> = ({ product }) => {
  const dispatch = useAppDispatch();
  const downloadSketch = () => {
    const link = process.env.REACT_APP_ASSETS_URL + '/' + product.vendor_code + '-PDF_MAIN.pdf';
    window.open(link, '_blank');
  };
  async function addToBasketApi(item: any) {
    dispatch(setFullBasket(await addToBasket(item.id, item.type)));
  }
  const downloadModel = () => {
    const link = process.env.REACT_APP_ASSETS_URL + '/' + product.vendor_code + '-STP.stp';
    window.open(link, '_blank');
  };
  const title = <div className='product-title'>
      <h4 className='vendor-code'>{product.vendor_code}</h4>
      <h3 className='name'>{product.name.toLowerCase()}</h3>
  </div>;
  return (
      <div className={'product-headline'}>
          {title}
          <img className='product_image' src={process.env.REACT_APP_ASSETS_URL + '/' + product.vendor_code + '-PNG_SKETCH.png'} alt='' />
          <div className={'product-container'}>
              {title}
              <ul className='list'>
                  <li className='list__item'>
                      <p className='title'>Категория:</p>
                      <p className='description'>{product.category_id.category}</p>
                  </li>
                  <li className='list__item'>
                      <p className='title'>Тип:</p>
                      <p className='description'>{product.type_id.type}</p>
                  </li>
                  <li className='list__item'>
                      <p className='title'>Интерфейс:</p>
                      <p className='description'>{product.interface_tool_id.interface_tool}</p>
                  </li>
                  <li className='list__item'>
                      <p className='title'>Внутренний подвод СОЖ:</p>
                      <p className='description'>{product.internal_coolant}</p>
                  </li>
                  {/*<li className='list__item'>*/}
                  {/*  <p> </p>*/}
                  {/*</li>*/}
                  {/*<li className='list__item'>*/}
                  {/*  <p className='title'>Внутренний подвод СОЖ:</p>*/}
                  {/*  <p className='description'>{product.internal_coolant}</p>*/}
                  {/*</li>*/}
              </ul>
              <div className='btn-container'>
                  <PrimaryBtn variant={variantType.SECONDARY} onClick={downloadSketch}>
                      <img src={downloadIcon} alt='' style={{ marginRight: '8px' }} /> 2D эскиз.pdf
                  </PrimaryBtn>
                  <PrimaryBtn variant={variantType.SECONDARY} onClick={downloadModel}>
                      <img src={downloadIcon} alt='' style={{ marginRight: '8px' }} /> 3D модель.stp
                  </PrimaryBtn>
              </div>
              <div>
                  <Divider />
              </div>
              <div className='price-container'>
                  {!!product?.deliveryTime &&
                      <div className='amount'>
                          <p className='title'>Срок поставки:</p>
                          <p className='description'>≈ {product?.deliveryTime} мес.</p>
                      </div>
                  }
                  <h4 className='price'>{numberWithSpaces(product.price)} ₽</h4>
                  <h5 className='ndc'>В том числе НДС 20%</h5>
              </div>
              <div className={'basket'}>
                  <AddToBasket product={product} type={'product'}/>
              </div>
          </div>
      </div>
  );
};
