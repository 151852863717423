import {Product} from "../../../../Shared/Types";
import {FC} from "react";
import './index.scss';
interface Props{
    product:Product
}
export const Characterisctic :FC<Props> = ({product}) => {
    return <>
        <section className={`characteristics`}>
            <div className="characteristics-content">
                <ul className="characteristics-list">
                    <li className="characteristics-list__characteristic">
                        <p className="title">{product.speed_max || '8000 об/мин'}</p>
                        <p className="description">
                            Максимальная скорость
                        </p>
                    </li>
                    <li className="characteristics-list__characteristic">
                        <p className="title">{product.gear_ratio_id?.gear_ratio || '1:1'}</p>
                        <p className="description">Передаточное отношение</p>
                    </li>
                    <li className="characteristics-list__characteristic">
                        <p className="title">{product.power_max || '10kWT'}</p>
                        <p className="description">Максимальная мощность</p>
                    </li>
                    <li className="characteristics-list__characteristic">
                        <p className="title">{product.torque_max || '42 H*M'}</p>
                        <p className="description">Максимальный момент</p>
                    </li>
                </ul>
            </div>
        </section>
    </>
}
