import { FC, ReactNode } from "react";
import { Product, IProduct, OptionalEquipment } from "../../../../Shared/Types";
import { ProductCard } from "../ProductCard/ProductCard";
import { OptionalEquipmentCard } from "../OptionalEquipmentCard/OptionalEquipmentCard";


type ProductType = Product | OptionalEquipment

interface ItemCardFactoryProps {
  type: "product" | "optionalEquipment",
  product: ProductType,
  features: ReactNode
}


export const ItemCardFactory: FC<ItemCardFactoryProps> = ({ type, product, features }) => {
  switch (type) {
    case "product":
      return <ProductCard product={product as Product} features={features} />;
      break;
    case "optionalEquipment":
      return <OptionalEquipmentCard product={product as OptionalEquipment} features={features} />;
      break;
    default:
      return <ProductCard product={product as Product} features={features} />;
  }

};
