import React, { FC, useState } from 'react';
import { PrimaryModal } from '../../../Shared/Ui/UIKit/modal';
import { modsType, PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import './index.scss';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { ModalsName, onClose, onOpen, setModalPhone, } from '../../../Shared/Redux/features/modalSlice';
import { useIsAuthenticated } from 'react-auth-kit';
import { PrimaryInput } from '../../../Shared/Ui/UIKit/input';
import {
  openSideBar,
  setBasketReturn,
  SideBarScreen,
  switchBeetwenComponent
} from '../../../Shared/Redux/features/sideBarSlice';
import { postOrderNoAuth } from '../../../Shared/Api/order';
import closeIcon from '../../../../src/assets/icons/modal-close.svg';

interface OrderModalProps {
  isMobile?: boolean
}

export const OrderModal: FC<OrderModalProps> = ({ isMobile }) => {
  const icon = React.createElement('img', { src: closeIcon, className: 'btn-close' }, null);
  const isAuthenticated = useIsAuthenticated();
  const { basket } = useAppSelector((state) => state.basket);
  const { isOpen, titleComponent } = useAppSelector((state) => state.sideBar);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const { orderModal } = useAppSelector((state) => state.modals.modals);
  const dispatch = useAppDispatch();
  const openModal = () => {
    dispatch(onOpen(ModalsName.ORDERMODAL));
  };
  const closeModal = () => {
    dispatch(onClose(ModalsName.ORDERMODAL));
  };
  const registrationPage = () => {
    closeModal();
    document.scrollingElement.scrollTop = 0;
    if (isMobile) {
      dispatch(switchBeetwenComponent(SideBarScreen.REGISTRATION));
    } else {
      dispatch(openSideBar(SideBarScreen.REGISTRATION));
    }
  };
  const loginPage = () => {
    closeModal();
    document.scrollingElement.scrollTop = 0;
    dispatch(setBasketReturn(true));
    if (isMobile) {
      dispatch(switchBeetwenComponent(SideBarScreen.AUTH));
    } else {
      dispatch(openSideBar(SideBarScreen.AUTH));
    }
  };
  const create = async () => {
    const result = await postOrderNoAuth({
      ...(name ? { name } : {}),
      ...(phone ? { phone } : {}),
      email,
      items: basket.map((e) => {
        return {
          ...(e.type === 'product' ? { productId: e.id } : { optionalEquipmentId: e.id }),
          count: e.quantity,
        };
      }),
    });
    if (result.id) {
      const orders = localStorage.getItem('orders');
      try {
        if (orders) {
          const arr = JSON.parse(orders);
          arr.push(result);
          localStorage.setItem('orders', JSON.stringify(arr));
        } else {
          localStorage.setItem('orders', JSON.stringify([result]));
        }
      } catch (e) {
        console.log('error writing to local storage');
        localStorage.removeItem('orders');
      }
      // dispatch(setBasket([]));
      dispatch(setModalPhone(phone));
      closeModal();
      dispatch(onOpen(ModalsName.ORDERSUCCESSMODAL));
    }
  };
  // async function confirm() {
  //   if (isAuthenticated()) dispatch(setFullBasket(await deleteCartItem(deleteModalCartId)));
  //   else {
  //     dispatch(deleteItemFromBasketNoAuth({ id: deleteModalItemId, type: deleteModalItemType }));
  //   }
  // }
  const body = <div className={'order-modal-body'}>
    <p className='description'>
      Заполните контактные данные, чтобы мы имели возможность связаться с вами, либо
      зарегистрируйтесь
    </p>
    <div className='form'>
      <div className={'field_container'}>
        <label htmlFor=''>Имя</label>
        <PrimaryInput
            placeholder={'Имя Фамилия'}
            onChange={(e, d) => setName(d.value)}
            value={name}
        />
      </div>
      <div className={'field_container'}>
        <label htmlFor=''>Email*</label>
        <PrimaryInput
            placeholder={'your.email@gmail.com'}
            onChange={(e, d) => setEmail(d.value)}
            value={email}
        />
      </div>
      <div className={'field_container'}>
        <label htmlFor=''>Телефон</label>
        <PrimaryInput
            placeholder={'+7 ( _ _ _ ) _ _ _ - _ _ - _ _ '}
            onChange={(e, d) => setPhone(d.value)}
            value={phone}
        />
      </div>
    </div>
    <div className={'link_container'}>
      <a className={'link'} onClick={loginPage}>
        Войти если уже есть аккаунт
      </a>
      <a className={'link'} onClick={registrationPage}>
        Нет учетной записи? Зарегистрироваться
      </a>
      {/*<a className={'link'} onClick={forgotPasswordPage}>*/}
      {/*  Забыли пароль?*/}
      {/*</a>*/}
    </div>
    <PrimaryBtn mods={modsType.MAXWIDTH} onClick={create} disabled={!email}>
      Запрос КП
    </PrimaryBtn>
  </div>

  return (
      <>
        {isMobile ? body
            : <PrimaryModal
                open={orderModal}
                onOpen={openModal}
                onClose={closeModal}
                size={'small'}
                basic={false}
                className={'order-modal modal-border-radius'}
                closeIcon={icon}
            >
              <div className='modal-content'>
                <h4 className='title'>
                  Контактная информация
                </h4>
                {body}
              </div>
            </PrimaryModal>
        }
      </>
  );
};
