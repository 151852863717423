import { instance } from '../index';
import { FiltersMachine } from '../../Redux/features/filters';
import { GetOptionalEquipmentResponse } from '../../Types/index';

export const getOptionalEquipment = async (
  params: FiltersMachine,
): Promise<GetOptionalEquipmentResponse> => {
  const { data } = await instance.get('catalog/optionalEquipments', {
    params: {
      equipmentCategory: params.category,
      equipmentType: params.type,
      page: params.page,
    },
  });
  return {
    items: mapEquipments(data.item.data),
    filters: {
      availableCategories: mapAvailableCategories(data.filters.equipmentCategories),
      availableTypes: mapAvailableTypes(data.filters.equipmentTypes),
    },
    meta: {
      totalItems: data.item.meta.total,
      itemCount: data.item.meta.total,
      itemsPerPage: data.item.meta.perPage,
      totalPages: data.item.meta.lastPage,
      currentPage: data.item.meta.currentPage,
    },
  };
};

function mapEquipments(items: any) {
  return items.map((e: any) => {
    return {
      id: e.id,
      vendor_code: e.listId,
      category_id: {
        id: e.equipmentCategory.id,
        category: e.equipmentCategory.category,
      },
      type_id: {
        id: e.equipmentType.id,
        type: e.equipmentType.type,
      },
      price: e.price.toString(),
      amount: e.amount.toString(),
      quantity: e.amount.toString(),
      description: e.description,
      type: 'optionalEquipment',
    };
  });
}

function mapAvailableCategories(items: any) {
  return items.map((e: any) => {
    return {
      id: e.equipmentCategory.id,
      category: e.equipmentCategory.category,
    };
  });
}

function mapAvailableTypes(items: any) {
  return items.map((e: any) => {
    return {
      id: e.equipmentType.id,
      type: e.equipmentType.type,
    };
  });
}
