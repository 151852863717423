import { FC,MouseEvent } from 'react';
import { Pagination, PaginationProps } from 'semantic-ui-react';
import './index.scss'; 

interface Props { 
    activePage:number,
    totalPages:number,
    onPageChange: (e:MouseEvent<HTMLAnchorElement>,data:PaginationProps) => void
}

export const PrimaryPagination :FC<Props> = ({activePage,totalPages,onPageChange}) => {
    return <Pagination
                boundaryRange={0}
                activePage={activePage}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={totalPages}
                onPageChange={onPageChange}
                secondary
                className='custom_pagination'
    />
}