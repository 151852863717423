import { instance } from '../index';
import { mapCart } from '../basket';

export const getOrders = async () => {
  const response = await instance.get('order');
  return response.data;
};

export const getOrder = async (id: number) => {
  const order = (await instance.get(`order/${id}`)).data;
  return {
    ...order,
    items: mapCart(order.items, true),
  };
};

export const postOrder = async () => {
  const order = (await instance.post('order')).data;
  return order;
};

export const postOrderNoAuth = async (data: any) => {
  const order = (await instance.post('order/no-auth', data)).data;
  return order;
};
