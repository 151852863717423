import React, { useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import './style/resetScss.scss';
import { Route, Routes } from 'react-router-dom';
import RootLayout from './Layout/root';
import AdditionalLayout from './Layout/additional';
import { Root } from '../Pages/Root/Ui/index';
import { ContactPage } from '../Pages/Contact/Ui/index';
import { ContactFormPage } from '../Pages/ContactForm/Ui/index';
import { CompanyPage } from '../Pages/Company/Ui';
import { CatalogPage } from '../Pages/Catalog/Ui';
import { ProductPage } from '../Pages/Product/Ui';
import { BasketPage } from '../Pages/Basket/Ui';
import { FavoritePage } from '../Pages/Favorites/Ui';
import { MyOrders } from '../Pages/MyOrders/Ui';
import { useAuthHeader, useIsAuthenticated } from 'react-auth-kit';
import { instance } from '../Shared/Api';
import { setFullBasket } from '../Shared/Redux/features/basketSlice';
import { useAppDispatch } from '../Shared/Redux/hooks';
import { fetchBasket } from '../Shared/Api/basket';
import { DeleteModal } from '../Entity/DeleteModal/Ui';
import { CompleteOrder } from '../Pages/CompleteOrder/Ui';

function App() {
  const dispatch = useAppDispatch();
  const authHeader = useAuthHeader();
  // const authUser = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  console.log('app:');
  console.log(authHeader());
  if (isAuthenticated()) instance.defaults.headers.common['Authorization'] = authHeader();
  else {
    const cart = localStorage.getItem('cart');
    if (cart) dispatch(setFullBasket(JSON.parse(cart)));
  }
  // console.log(authUser());
  console.log(isAuthenticated());
  useEffect(() => {
    async function initBasket() {
      const basket = await fetchBasket();
      dispatch(setFullBasket(basket));
    }
    if (isAuthenticated()) initBasket();
  });
  // getBasket();
  return (
    <>
      <div className='App'>
        <Routes>
          <Route path='/' element={<AdditionalLayout />}>
            <Route index element={<Root />} />
            <Route path={'favorite'} element={<FavoritePage />} />
            <Route path={'company'} element={<CompanyPage />} />
          </Route>
          <Route path='/' element={<RootLayout />}>
            <Route path={'contact'} element={<ContactPage />} />
            <Route path={'contactForm'} element={<ContactFormPage />} />
            <Route path='catalog' element={<CatalogPage />} />
            <Route path={'product/:id'} element={<ProductPage />} />
            <Route path={'order/:id'} element={<CompleteOrder />} />
            <Route path={'basket'} element={<BasketPage />} />
            <Route path={'myOrders'} element={<MyOrders />} />
          </Route>
        </Routes>
      </div>

      <DeleteModal />
    </>
  );
}

export default App;
