import MobileMenu from './mobileMenu';
// import MobileSearch from './mobileSearch';
import React, { SyntheticEvent, useRef } from 'react';
import logo from '../../../assets/icons/headerLogo.svg';
import subLogo from '../../../assets/icons/subLogo.svg';
import './index.scss';
import { Link, useLocation } from 'react-router-dom';
import mobileMenuIcon from '../../../assets/icons/mobile-menu.svg';
import { Basket } from './basket';
import { User } from './user';
import Search from './Search';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { closeSideBar } from '../../../Shared/Redux/features/sideBarSlice';
import { closeMobileMenu, openMobileMenu } from '../../../Shared/Redux/features/mobileMenuSlice';

const Header = () => {
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector((state) => state.sideBar);
  const { isOpen: isMobileMenuOpen } = useAppSelector((state) => state.mobileMenu);

  const clickIgnoreRef = useRef(null);

  const location = useLocation();

  const onClick = (e: SyntheticEvent) => {
    if (isOpen && e.target !== clickIgnoreRef.current) {
      dispatch(closeSideBar());
    }
    if (isMobileMenuOpen && e.target !== clickIgnoreRef.current) {
      dispatch(closeMobileMenu());
    }
  };

  const assetUrl = process.env.REACT_APP_ASSETS_URL;
  return (
    <header
      className={`
          header
          ${location.pathname === '/' ? 'home' : ''}
          ${location.pathname === '/company' ? 'company' : ''}
          ${isOpen || isMobileMenuOpen ? 'with-aside' : ''}
        `}
      onClick={onClick}
    >
      <div className='wrapper'>
        <div className='header-content'>
          <span className='sub-logo'>
            <img src={subLogo} alt='' />
          </span>
          <h1 className='logo'>
            <Link to={'/'}>
              <img src={logo} alt='' />
            </Link>
          </h1>

          <div className={'click-ignore'} ref={clickIgnoreRef} />

          <nav>
            <ul className='list-pages'>
              <li className='list-pages__page'>
                <Link to='/company'>О компании</Link>
              </li>

              <li
                className={`
                  list-pages__page
                  ${location.pathname.includes('catalog') ? 'active' : ''}
                `}
              >
                <Link to='/catalog'>Каталог</Link>
              </li>
              <li
                  className={`
                    list-pages__page
                    ${location.pathname.includes('contact') ? 'active' : ''}
                  `}
              >
                <Link to='/contact'>Контакты</Link>
              </li>
              {/*<li className="list-pages__page">*/}
              {/*  <a href={`${assetUrl}download-catalog/catalog.pdf`} download>Скачать каталог</a>*/}
              {/*</li>*/}
            </ul>
            <Search />
            {/*<HeaderInput></HeaderInput>*/}
            <User />
            <Basket />
          </nav>
          <span
              className='mobile-menu'
              onClick={() => isMobileMenuOpen ? dispatch(closeMobileMenu()) : dispatch(openMobileMenu())}
          >
            <img src={mobileMenuIcon} alt="" />
          </span>
          <MobileMenu />
          {/*<MobileSearch isOpen={search} handleSearch={handleSearch} handlerMenu={handlerMenu}></MobileSearch>*/}
        </div>
      </div>
    </header>
  );
};
export default Header;
