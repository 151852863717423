import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CompatibleModel, CompatibleManufacturer } from '../../Types';
import { getCompatibleData } from '../../Api/compatible_equipment';

export interface CompatibleData {
  availableManufacturer: CompatibleManufacturer[];
  compatibleModel: CompatibleModel[];
}

interface InitialState {
  compatibleData: CompatibleData;
}

const initialState: InitialState = {
  compatibleData: {
    availableManufacturer: [],
    compatibleModel: [],
  },
};

export const setCompatibleData = createAsyncThunk('', (manufacturerId: number | undefined) =>
  getCompatibleData(manufacturerId),
);

export const CompatibleEquipmentSlice = createSlice({
  name: 'CompatibleEquipment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCompatibleData.fulfilled, (state, action) => {
      state.compatibleData.availableManufacturer = action.payload.availableManufacturer;
      state.compatibleData.compatibleModel = action.payload.response;
      // console.log(action.payload);
    });
  },
});

export const {} = CompatibleEquipmentSlice.actions;
export default CompatibleEquipmentSlice.reducer;
