import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { numberWithSpaces } from '../../../../Shared/Helpers/functions/numbersWithSpaces';
import { Product } from '../../../../Shared/Types';
import './index.scss';

enum Title {
  Category = 'Категория',
  Type = 'Тип',
  Internal_cooling = 'Внутренний подвод СОЖ',
  Delivery_time = 'Срок поставки',
}

interface List {
  title: string;
  description: string;
}

interface ProductProps {
  product: Product;
}
export const SearchResult: FC<ProductProps> = ({ product }) => {
  const createImgUrl = (code: string) => `${process.env.REACT_APP_ASSETS_URL}/${code}-PNG_IZO.png`;

  const createOptionsList = (product: Product): Array<List> => {
    return [
      {
        title: `${Title.Category}:`,
        description: product.category_id.category,
      },
      {
        title: `${Title.Type}:`,
        description: product.type_id.type,
      },
      {
        title: `${Title.Internal_cooling}:`,
        description: product.internal_coolant,
      },
      {
        title: `${Title.Delivery_time}:`,
        description: `~2 мес`,
      },
    ];
  };

  return (
      <Link to={`/product/${product.id}`}>
        <li className='search-result'>
          <div className='card__header'>
            <div className='img_container'>
              <img src={createImgUrl(product.vendor_code)} alt={product.name} />
            </div>
            <div className='features'>
              <ul className='list'>
                <li className='item__vendor_code'>
                  {product.vendor_code}
                </li>
                <li className='item__name'>
                  {product.name}
                </li>
              </ul>
              <div className='price__container'>
                <h3 className='price'>{numberWithSpaces(product.price)} ₽</h3>
              </div>
            </div>
          </div>
          <div className='options'>
            <ul className='list'>
              {createOptionsList(product).map(({ title, description }) => (
                  <li className='item__field' key={title}>
                    <span className='title'>{title} </span>
                    <span className='description'>{description}</span>
                  </li>
              ))}
            </ul>
          </div>
        </li>
      </Link>
  );
};
