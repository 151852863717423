import { instance } from '../index';
import { IProduct } from '../../Types';

export const fetchBasket = async () => {
  const items = (await instance.get('cart')).data.items;
  const basket = mapCart(items);
  console.log(basket);
  return basket;
};

export const addToBasket = async (id: number, type: string) => {
  const response = await instance.post('cart', {
    count: 1,
    ...(type === 'product' ? { productId: id } : { optionalEquipmentId: id }),
  });
  return mapCart(response.data.items);
};

export const changeBasketItemCount = async (itemId: number, count: number) => {
  const response = await instance.post('cart/set-count', {
    itemId,
    count,
  });
  return mapCart(response.data.items);
};

export const deleteCartItem = async (itemId: number) => {
  const response = await instance.delete(`cart/${itemId}`);
  return mapCart(response.data.items);
};

export const getSpecificationPdf = async () => {
  const response = await instance.get('cart/specification');
  return response.data;
};

export const getSpecificationOrder = async (orderId: number) => {
  return  (await instance.get(`cart/specification/${orderId}`)).data
}
export const getSpecificationPdfNoAuth = async (basket: IProduct[]) => {
  const response = await instance.post(
    'cart/specification/no-auth',
    basket.map((e) => {
      return {
        ...(e.type === 'product' ? { productId: e.id } : { optionalEquipmentId: e.id }),
        count: e.quantity,
      };
    }),
  );
  return response.data;
};

export function mapCart(items: any, isOrder = false) {
  console.log('items');
  console.log(items);
  const s = items
    .map((e: any) => {
      if (e.productId) {
        return {
          amount: e.product.amount,
          category_id: e.product.category,
          // equipment: [],
          gear_ratio_id: {
            id: e.product.gearRatio?.id,
            gear_ratio: e.product.gearRatio?.getRatio,
          },
          id: e.product.id,
          cartId: e.id,
          interface_tool_id: {
            id: e.product.interfaceTool.id,
            interface_tool: e.product.interfaceTool.interfaceTool,
          },
          internal_coolant: e.product.internalCoolant,
          name: e.product.name,
          power_max: e.product.power_max,
          price: e.product.price.toString(),
          quantity: e.count,
          speed_max: e.product.speedMax,
          torque_max: e.product.torqueMax,
          type: 'product',
          type_id: {
            id: e.product.type.id,
            type: e.product.type.type,
          },
          vendor_code: e.product.listId,
          weight: e.product.weight,
          isOrder,
        };
      } else if (e.optionalEquipmentId) {
        return {
          amount: e.optionalEquipment.amount,
          category_id: {
            id: e.optionalEquipment.equipmentCategory.id,
            category: e.optionalEquipment.equipmentCategory.category,
          },
          description: e.optionalEquipment.description,
          id: e.optionalEquipment.id,
          cartId: e.id,
          price: e.optionalEquipment.price.toString(),
          quantity: e.count,
          type: 'optionalEquipment',
          type_id: {
            id: e.optionalEquipment.equipmentType.id,
            type: e.optionalEquipment.equipmentType.type,
          },
          vendor_code: e.optionalEquipment.listId,
          isOrder,
        };
      }
    })
    .sort((q: any, w: any) => q.cartId - w.cartId);
  console.log(s);
  return s;
}
