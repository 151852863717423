import { ChangeEvent, FC } from 'react';
import { Input, InputProps } from 'semantic-ui-react';
import './index.scss';

interface Props {
  type?: string;
  placeholder?: string;
  error?: boolean;
  loading?: boolean;
  className?: string;
  onChange?: (e: ChangeEvent, data: InputProps) => void;
  otherProps?: Object;
  value?: string;
  disabled?: boolean;
}

export const PrimaryInput: FC<Props> = ({
  type = 'text',
  placeholder = 'Placeholder',
  error = false,
  otherProps,
  loading = false,
  onChange = (e, data) => { },
  className,
  value = '',
  disabled = false,
}) => {
  return (
    <Input
      type={type}
      placeholder={placeholder}
      error={error}
      loading={loading}
      onChange={onChange}
      className={className}
      value={value}
      {...otherProps}
      disabled={disabled}
    />
  );
};
