import { configureStore } from '@reduxjs/toolkit'
import BasketSlice from './features/basketSlice';
import SideBarSlice from './features/sideBarSlice';
import ProductSlice from './features/product';
import filtersSlice from './features/filters';
import compatibleEquipmentSlice from './features/compatibleEquipment';
import OptionalEquipmentsSlice from './features/optionalEquipments';
import ModalSlice from './features/modalSlice';
import MobileMenuSlice from './features/mobileMenuSlice';
import MobileFiltersSlice from './features/mobileFiltersSlice';

export const store = configureStore({
  reducer: {
    basket:BasketSlice,
    sideBar:SideBarSlice,
    product:ProductSlice,
    filters:filtersSlice,
    compatible:compatibleEquipmentSlice,
    optionalEquipments:OptionalEquipmentsSlice,
    modals:ModalSlice,
    mobileMenu:MobileMenuSlice,
    mobileFilters:MobileFiltersSlice,
  },
})
export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch