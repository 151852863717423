import { modsType, PrimaryBtn } from '../../../Shared/Ui/UIKit/button';
import { PrimaryInput } from '../../../Shared/Ui/UIKit/input';
import './index.scss';
import { useEffect, useState } from 'react';
import { getOrganization, postOrganization } from '../../../Shared/Api/organization';

export const ClientDataPage = () => {
  const [inn, setInn] = useState('');
  const [name, setName] = useState('');
  const [kpp, setKpp] = useState('');
  const [address, setAddress] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [requestError, setRequestError] = useState(false);
  useEffect(() => {
    async function mounted() {
      if (inn) return;
      const response = await getOrganization();
      if (response) {
        setDisabled(true);
        setInn(response.inn);
        setName(response.name);
        setKpp(response.kpp);
        setAddress(response.address);
      }
    }
    mounted();
  });

  async function save() {
    try {
      setRequestError(false);
      const response = await postOrganization(inn);
      setName(response.name);
      setKpp(response.kpp);
      setAddress(response.address);
    } catch (error) {
      setRequestError(true);
    }
  }

  return (
    <>
      <form
        className='client_data_form'
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
      >
        <div className={'fields'}>
          <h3 className='title'>
            Заполните поле ИНН, чтобы иметь возможность автоматического выставления счетов и участия
            в программе лояльности
          </h3>
          <div className={'field_container'}>
            <label htmlFor=''>ИНН</label>
            <PrimaryInput
              placeholder={' '}
              onChange={(e, d) => setInn(d.value)}
              value={inn}
              disabled={disabled}
              error={requestError}
            />
            {requestError && <div className='error-text'>Не удалось найти организацию</div>}
          </div>
          <div className={'field_container'}>
            <label htmlFor=''>Наименование организации</label>
            <PrimaryInput placeholder={''} value={name} disabled={true} />
          </div>
          <div className={'field_container'}>
            <label htmlFor=''>КПП</label>
            <PrimaryInput placeholder={''} value={kpp} disabled={true} />
          </div>
          <div className={'field_container'}>
            <label htmlFor=''>Адрес</label>
            <PrimaryInput placeholder={''} value={address} disabled={true} />
          </div>
        </div>
        {!disabled && <PrimaryBtn mods={modsType.MAXWIDTH}>Сохранить</PrimaryBtn>}
        {inn && disabled && <PrimaryBtn mods={modsType.MAXWIDTH} onClick={() => setDisabled(false)}>Изменить</PrimaryBtn>}
      </form>
    </>
  );
};
