import axios from 'axios';
const { REACT_APP_API_URL } = process.env;

export const baseHttp = REACT_APP_API_URL;
export const instance = axios.create({
  baseURL: baseHttp,
  headers: {
    // Authorization: `Bearer ${JSON.parse(localStorage.authorized).bearer.value}`,
  },
});
