import { FC, ReactNode } from 'react';
import { Product } from '../../../../Shared/Types';
import { ItemCard } from '..';
import { Link } from 'react-router-dom';
import "./ProductCard.scss";
import { numberWithSpaces } from '../../../../Shared/Helpers/functions/numbersWithSpaces';

interface ProductCardProps {
  product: Product;
  features: ReactNode;
}

export const ProductCard: FC<ProductCardProps> = ({ product, features }) => {
  const createImgUrl = (code: any) => `${process.env.REACT_APP_ASSETS_URL}/${code}-PNG_IZO.png`;
  const img = () => <img src={createImgUrl(product.vendor_code)} alt='' />;
  const list = () => (
    <>
      <ul className='list'>
        <li className='item__vendor_code'>
          <Link to={`/product/${product.id}`}>{product?.vendor_code || '-'}</Link>
        </li>

        <li className='item__name'>
          <Link to={`/product/${product.id}`}>{product.name}</Link>
        </li>
        <li className='item__price'>
          {numberWithSpaces(product.price.toString())} ₽
        </li>
      </ul>
      <ul className="list">
        <li className="item__field">
          <span className="title">Категория: </span>
          <span className="description">{product?.category_id.category || '-'}</span>
        </li>
        <li className='item__field'>
          <span className='title'>Тип: </span>
          <span className='description'>{product.type_id.type || '-'}</span>
        </li>

        <li className='item__field'>
          <span className='title'>Интерфейс: </span>
          <span className='description'>{product?.interface_tool_id.interface_tool || '-'}</span>
        </li>
        <li className='item__field'>
          <span className='title'>Внутренний подвод СОЖ: </span>
          <span className='description'>{product.internal_coolant}</span>
        </li>
        {!!product?.deliveryTime && (
          <li className='item__field'>
            <br></br>
            <span className='title'>Срок поставки: </span>
            <span className='description'>≈ {product?.deliveryTime} мес.</span>
          </li>
        )}
      </ul>
    </>
  );

  return <ItemCard list={list()} product={product} features={features} imgNode={img()} />;
};
