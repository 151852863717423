import './index.scss'
import {FC} from "react";
import { Product} from "../../../../Shared/Types";
interface Props {
    product:Product,
}
export const VisualModel :FC<Props> =({product})=> {
    const url = process.env.REACT_APP_ASSETS_URL+'/'+`${product.vendor_code}-GLB.glb`;

    return (
        <div className='product-model__content'>

            {/*@ts-ignore*/}
            <model-viewer id="modelViewer" alt="" src={url} ar ar-modes="" environment-image="" poster="" seamless-poster shadow-intensity="1" camera-controls enable-pan></model-viewer>
        </div>
    )
}
