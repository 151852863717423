import { createSlice } from '@reduxjs/toolkit';
interface MobileFiltersState {
  isOpen: boolean;
}
const initialState: MobileFiltersState = {
  isOpen: false,
};

export const mobileFiltersSlice = createSlice({
  name: 'mobileFilters',
  initialState,
  reducers: {
    openMobileFilters: (state) => {
      state.isOpen = true;
    },
    closeMobileFilters: (state) => {
      state.isOpen = false;
    },
  },
  extraReducers: {},
});
export const { openMobileFilters, closeMobileFilters } = mobileFiltersSlice.actions;
export default mobileFiltersSlice.reducer;
