import React, { FC, useState } from 'react';
import { PrimaryModal } from '../../../Shared/Ui/UIKit/modal';
import { PrimaryBtn, modsType } from '../../../Shared/Ui/UIKit/button';
import closeIcon from '../../../assets/icons/modal-close.svg';
import PhoneInput from 'react-phone-input-2';
import { ModalsName, onClose, onOpen } from '../../../Shared/Redux/features/modalSlice';
import './index.scss';
import { useAppDispatch, useAppSelector } from '../../../Shared/Redux/hooks';
import { sendPhone } from '../../../Shared/Api/callback-request';
import { appOptions } from "../../../Shared/options";

export const ContactModal: FC = () => {
  const icon = React.createElement('img', { src: closeIcon, className: 'btn-close' }, null);
  const [step, setStep] = useState('1');
  const [phone, setPhone] = useState(null);
  const dispatch = useAppDispatch();
  const { contactModal } = useAppSelector((state) => state.modals.modals);
  const openModal = () => {
    dispatch(onOpen(ModalsName.CONTACT));
  };
  const closeModal = () => {
    dispatch(onClose(ModalsName.CONTACT));
  };
  const stepHandler = (step: string) => {
    setStep(step);
  };
  const send = async () => {
    const response = await sendPhone(phone);
    if (response.deliveryId) stepHandler('2');
  };
  const steps: any = {
    1: {
      title: 'Связаться с нами',
      description:
        `Позвоните нам по номеру телефона ${appOptions.contacts.phoneUrl} или оставьте свой номер телефона, и мы свяжемся с вами в ближайшее время`,
    },
    2: {
      title: 'Мы с вами свяжемся',
      description: 'Наш специалист скоро перезвонит по этому номеру',
    },
  };
  return (
    <PrimaryModal
      open={contactModal}
      onOpen={openModal}
      onClose={closeModal}
      className="contact-modal"
      size={'tiny'}
      basic={false}
      closeIcon={icon}
    >
      <div className='modal-content'>
        <h3 className='title'>{steps[step].title}</h3>
        <p className='description' dangerouslySetInnerHTML={{__html:steps[step].description }}></p>

        {step === '1' ? (
          <>
            <PhoneInput
              country={'ru'}
              disableDropdown={true}
              inputStyle={{
                width: '100%',
                border: '1px solid #E6E6E6',
                padding: '14px 24px',
                color: '#999',
                outline: 'none',
                borderRadius: '4px',
              }}
              onlyCountries={['ru']}
              inputProps={{
                specialLabel: 'd',
              }}
              onChange={setPhone}
              value={phone}
            />
            <PrimaryBtn mods={modsType.MAXWIDTH} onClick={send}>
              Отправить
            </PrimaryBtn>
          </>
        ) : (
          <>
            <p className='back' onClick={() => stepHandler('1')}>
              Отправить другой номер
            </p>
          </>
        )}
      </div>
    </PrimaryModal>
  );
};
