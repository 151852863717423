import { Form } from 'semantic-ui-react';
import { PrimaryCheckBox } from '../../../../Shared/Ui/UIKit/checkbox';
import React, { FC, useEffect, useState } from 'react';
import { PrimaryDropdown } from '../../../../Shared/Ui/UIKit/dropDown';
import { useAppDispatch, useAppSelector } from '../../../../Shared/Redux/hooks';
import { transformToDropDownData } from '../../../../Shared/Helpers/functions/transformToDropDownData';
import {
    changeFilterGroup,
    changeFiltersCatalog,
    changeFiltersEquipment,
    changeFiltersMachine,
    SelectFilterGroup,
} from '../../../../Shared/Redux/features/filters';
import { FilterCatalog } from '../../../../Shared/Types';
import './index.scss';
import arrowIcon from '../../../../assets/icons/down-arrow-dark.svg';
import { PrimaryBtn } from '../../../../Shared/Ui/UIKit/button';
import { closeMobileFilters } from '../../../../Shared/Redux/features/mobileFiltersSlice';

interface CategoryGroupProps {
    isMobile: boolean;
    openedProperty: string;
    setOpenedProperty: (property: string) => void;
}

const CategoryFilterGroup: FC<CategoryGroupProps> = ({ isMobile, openedProperty, setOpenedProperty }) => {
  const dispatch = useAppDispatch();

  const { availableCategories, availableGearRatios, availableInterfaces, availableTypes } =
    useAppSelector((state) => state.product.productData.filters);
  const { category, type, gear_ratio, interface_tool } = useAppSelector(
    (state) => state.filters.filtersCatalog,
  );

  const clickHandler = (name: keyof FilterCatalog) => (e: any, data: any) => {
    dispatch(changeFiltersCatalog({ name: name, selectedItem: data.value }));
    if (name === 'category' && data.value === undefined) {
      dispatch(changeFiltersCatalog({ name: 'type', selectedItem: undefined }));
      dispatch(changeFiltersCatalog({ name: 'interface_tool', selectedItem: undefined }));
      dispatch(changeFiltersCatalog({ name: 'gear_ratio', selectedItem: undefined }));
    }
  };
  return (
    <>
      <div style={{ width: '100%' }}>
        <h3 className='filter-title'>Интерфейс станка</h3>
        <PrimaryDropdown
          optionsList={transformToDropDownData(availableCategories, 'category')}
          onClick={clickHandler('category')}
          placeholder={'Интерфейс станка'}
          value={category}
          otherProps={{
              ...(isMobile ? {
                  closeOnChange: !isMobile,
                  open: openedProperty === 'category',
                  onOpen: () => setOpenedProperty('category')
              } : null)
        }}
        />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className='filter-title'>Тип</h3>
        <PrimaryDropdown
          optionsList={transformToDropDownData(availableTypes, 'type')}
          onClick={clickHandler('type')}
          disabled={category === undefined}
          placeholder={'Тип'}
          value={type}
          otherProps={{
              ...(isMobile ? {
                  closeOnChange: !isMobile,
                  open: openedProperty === 'type',
                  onOpen: () => setOpenedProperty('type')
              } : null)
          }}
        />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className='filter-title'>Интерфейс инструмента</h3>
        <PrimaryDropdown
          optionsList={transformToDropDownData(availableInterfaces, 'interface_tool')}
          onClick={clickHandler('interface_tool')}
          disabled={type === undefined}
          placeholder={'Интерфейс'}
          value={interface_tool}
          otherProps={{
              ...(isMobile ? {
                  closeOnChange: !isMobile,
                  open: openedProperty === 'interface_tool',
                  onOpen: () => setOpenedProperty('interface_tool')
              } : null)
          }}
        />
      </div>
      {/*<div style={{ width: '100%' }}>*/}
      {/*  <h3 className='filter-title'>Передаточное отношение</h3>*/}
      {/*  <PrimaryDropdown*/}
      {/*    optionsList={transformToDropDownData(availableGearRatios, 'gear_ratio')}*/}
      {/*    onClick={clickHandler('gear_ratio')}*/}
      {/*    disabled={interface_tool === undefined}*/}
      {/*    placeholder={'Передаточное отношение'}*/}
      {/*    value={gear_ratio}*/}
      {/*    otherProps={{*/}
      {/*        ...(isMobile ? {*/}
      {/*            closeOnChange: !isMobile,*/}
      {/*            open: openedProperty === 'gear_ratio',*/}
      {/*            onOpen: () => setOpenedProperty('gear_ratio')*/}
      {/*        } : null)*/}
      {/*      }}*/}
      {/*  />*/}
      {/*</div>*/}
    </>
  );
};

const EquipmentFilterGroup: FC<CategoryGroupProps> = ({ isMobile, openedProperty, setOpenedProperty }) => {
  const dispatch = useAppDispatch();

  const { availableTypes, availableInterfaces } = useAppSelector(
    (state) => state.product.productData.filters,
  );

  const { availableManufacturer, compatibleModel } = useAppSelector(
    (state) => state.compatible.compatibleData,
  );

  const { category, type, interface_tool } = useAppSelector(
    (state) => state.filters.filtersCatalog,
  );
  const { manufacturer } = useAppSelector((state) => state.filters.filtersEquipment);
  const [model, setModel] = useState('');
  const clickHandler = (name: keyof FilterCatalog) => (e: any, data: any) => {
    dispatch(changeFiltersCatalog({ name: name, selectedItem: data.value }));
  };
  const manufactureHandler = (name: any) => (e: any, data: any) => {
    dispatch(changeFiltersEquipment({ name: name, selectedItem: data.value }));
    setModel(undefined);
  };
  const modelHandler = (name: any) => (e: any, data: any) => {
    const exOpt = data.options.find((item: any) => item.value === data.value);
    setModel(data.value);
    dispatch(changeFiltersCatalog({ name: name, selectedItem: exOpt.categoryId }));
  };
  return (
    <>
      <div style={{ width: '100%' }}>
        <h3 className='filter-title'>Производитель</h3>
        <PrimaryDropdown
          optionsList={transformToDropDownData(availableManufacturer, 'name')}
          onClick={manufactureHandler('manufacturer')}
          placeholder={'Производитель'}
          value={manufacturer}
          otherProps={{
              ...(isMobile ? {
                  closeOnChange: !isMobile,
                  open: openedProperty === 'manufacturer',
                  onOpen: () => setOpenedProperty('manufacturer')
              } : null)
          }}
        />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className='filter-title'>Модель станка</h3>
        <PrimaryDropdown
          optionsList={transformToDropDownData(compatibleModel, 'name')}
          onClick={modelHandler('category')}
          disabled={manufacturer === undefined ? true : false}
          placeholder={'Модель'}
          value={model}
          otherProps={{
              ...(isMobile ? {
                  closeOnChange: !isMobile,
                  open: openedProperty === 'category',
                  onOpen: () => setOpenedProperty('category')
              } : null)
          }}
        />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className='filter-title'>Тип</h3>
        <PrimaryDropdown
          optionsList={transformToDropDownData(availableTypes, 'type')}
          onClick={clickHandler('type')}
          disabled={category === undefined ? true : false}
          placeholder={'Тип'}
          value={type}
          otherProps={{
              ...(isMobile ? {
                  closeOnChange: !isMobile,
                  open: openedProperty === 'type',
                  onOpen: () => setOpenedProperty('type')
              } : null)
          }}
        />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className='filter-title'>Интерфейс инструмента</h3>
        <PrimaryDropdown
          optionsList={transformToDropDownData(availableInterfaces, 'interface_tool')}
          onClick={clickHandler('interface_tool')}
          disabled={type === undefined ? true : false}
          placeholder={'Интерфейс инструмента'}
          value={interface_tool}
          otherProps={{
              ...(isMobile ? {
                  closeOnChange: !isMobile,
                  open: openedProperty === 'interface_tool',
                  onOpen: () => setOpenedProperty('interface_tool')
              } : null)
          }}
        />
      </div>
    </>
  );
};

const MachinesFilterGroup: FC<CategoryGroupProps> = ({ isMobile, openedProperty, setOpenedProperty }) => {
  const dispatch = useAppDispatch();

  const { optionalEquipmentsType, optionalEquipmentsCategory, optionalEquipments } = useAppSelector(
    (state) => state.optionalEquipments.optionalEquipmentsData,
  );
  const { category, type } = useAppSelector((state) => state.filters.filtersMachine);

  const clickHandler = (name: any) => (e: any, data: any) => {
    dispatch(changeFiltersMachine({ name: name, selectedItem: data.value }));
  };
  return (
    <>
      <div style={{ width: '100%' }}>
        <h3 className='filter-title'>Категория</h3>
        <PrimaryDropdown
          optionsList={transformToDropDownData(optionalEquipmentsCategory, 'category')}
          onClick={clickHandler('category')}
          placeholder={'Комплектующие СОЖ'}
          value={category}
          otherProps={{
              ...(isMobile ? {
                  closeOnChange: !isMobile,
                  open: openedProperty === 'category',
                  onOpen: () => setOpenedProperty('category')
              } : null)
          }}
        />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className='filter-title'>Тип</h3>
        <PrimaryDropdown
          optionsList={transformToDropDownData(optionalEquipmentsType, 'type')}
          onClick={clickHandler('type')}
          disabled={category === undefined ? true : false}
          placeholder={'Тип'}
          value={type}
          otherProps={{
              ...(isMobile ? {
                  closeOnChange: !isMobile,
                  open: openedProperty === 'type',
                  onOpen: () => setOpenedProperty('type')
              } : null)
          }}
        />
      </div>
    </>
  );
};

interface Props {
  currentFilterGroup: string;
  openedProperty?: string;
  isMobile?: boolean;
  setOpenProperty?: (property: string) => void;
}

const SwitcherFilterGroup: FC<Props> = ({ currentFilterGroup, openedProperty, isMobile, setOpenProperty }) => {
  switch (currentFilterGroup) {
    case 'category':
      return <CategoryFilterGroup isMobile={isMobile} openedProperty={openedProperty} setOpenedProperty={setOpenProperty} />;
    case 'equipment':
      return <EquipmentFilterGroup isMobile={isMobile} openedProperty={openedProperty} setOpenedProperty={setOpenProperty} />;
    case 'machines':
      return <MachinesFilterGroup isMobile={isMobile} openedProperty={openedProperty} setOpenedProperty={setOpenProperty} />;
  }
};
export const CatalogFilters = () => {
  const dispatch = useAppDispatch();
  const currentFilterGroup = useAppSelector((state) => state.filters.selectFilterGroup);
  const changeHandler = (e: any, data: any) => {
    dispatch(changeFilterGroup(data.value));
  };

  return (
    <div className="filters">
      <Form>
        <h3 className='filters-title'>Тип поиска</h3>
        <Form.Field className='fields_container'>
          <PrimaryCheckBox
            label={'Интерфейс станка'}
            checkedName={currentFilterGroup}
            onChange={changeHandler}
            value={SelectFilterGroup.CATEGORY}
          />
          <PrimaryCheckBox
            label={'Оборудование'}
            checkedName={currentFilterGroup}
            onChange={changeHandler}
            value={SelectFilterGroup.EQUIPMENT}
          />
          <PrimaryCheckBox
            label={'Комплектующие'}
            checkedName={currentFilterGroup}
            onChange={changeHandler}
            value={SelectFilterGroup.MACHINES}
          />
        </Form.Field>
      </Form>
      <div className="dropdowns">
        <SwitcherFilterGroup currentFilterGroup={currentFilterGroup} />
      </div>
    </div>
  );
};

const groupNames: {[key: string]: string} = {
    category: 'Интерфейсы станка',
    type: 'Типы',
    manufacturer: 'Производители',
    interface_tool: 'Интерфейсы',
    gear_ratio: 'Передаточные отношения',
    model: 'Модели'
};

const groupSubtitles: {[key: string]: string} = {
    category: 'Выберите интерфейс станка',
    type: 'Выберите тип',
    manufacturer: 'Выберите производителя',
    interface_tool: 'Выберите интерфейс',
    gear_ratio: 'Выберете передаточное отношение',
    model: 'Выберите модель'
};

export const CatalogFiltersMobile = () => {
    const isOpen = useAppSelector((state) => state.mobileFilters.isOpen);
    const isMenuOpen = useAppSelector((state) => state.mobileMenu.isOpen);
    const isSideBarOpen = useAppSelector((state) => state.sideBar.isOpen);
    const [active, setActive] = useState(null);
    const [openedProperty, setOpenedProperty] = useState(null);
    const dispatch = useAppDispatch();
    const currentFilterGroup = useAppSelector((state) => state.filters.selectFilterGroup);
    const changeHandler = (e: any, data: any) => {
        dispatch(changeFilterGroup(data.value));
    };

    const handleClose = () => {
        if (openedProperty) {
            setOpenedProperty(null);
        } else {
            setActive(false);
            dispatch(closeMobileFilters());
        }
    };

    const onBack = () => {
        if (openedProperty) {
            setOpenedProperty(null);
        } else {
            handleClose();
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.documentElement.style.overflow = 'hidden';
            document.body.scrollTo({ top: 0 });
        } else if (!isMenuOpen && !isSideBarOpen) {
            document.documentElement.style.overflow = 'auto'
        }
        setActive(isOpen);
    }, [isOpen]);

    return (
        <div className={`filters mobile ${active ? 'active' : ''} ${openedProperty ? 'property' : ''}`}>
            <div className="filters-mobile-title">
                <h3 className="title">
                    {openedProperty ? groupNames[currentFilterGroup === SelectFilterGroup.EQUIPMENT
                        ? 'model' : openedProperty] : 'Фильтры'}
                </h3>
                <div className="close_icon" onClick={onBack}><img src={arrowIcon} alt="" /></div>
            </div>
            {openedProperty &&
                <div className="filters-title">
                    {groupSubtitles[currentFilterGroup === SelectFilterGroup.EQUIPMENT ? 'model' : openedProperty]}
                </div>
            }
            <Form>
                <h3 className='filters-title'>Тип поиска</h3>
                <Form.Field className='fields_container'>
                    <PrimaryCheckBox
                        label={'Интерфейс станка'}
                        checkedName={currentFilterGroup}
                        onChange={changeHandler}
                        value={SelectFilterGroup.CATEGORY}
                    />
                    <PrimaryCheckBox
                        label={'Оборудование'}
                        checkedName={currentFilterGroup}
                        onChange={changeHandler}
                        value={SelectFilterGroup.EQUIPMENT}
                    />
                    <PrimaryCheckBox
                        label={'Комплектующие'}
                        checkedName={currentFilterGroup}
                        onChange={changeHandler}
                        value={SelectFilterGroup.MACHINES}
                    />
                </Form.Field>
            </Form>
            <div className="dropdowns">
                <SwitcherFilterGroup
                    currentFilterGroup={currentFilterGroup}
                    isMobile
                    openedProperty={openedProperty}
                    setOpenProperty={setOpenedProperty}
                />
            </div>
            <PrimaryBtn onClick={handleClose}>{openedProperty ? 'Сохранить' : 'Применить'}</PrimaryBtn>
        </div>
    );
};
