import { FC, ReactNode } from 'react';
import favorite from '../../../../assets/icons/favourites.svg';
import './index.scss';
interface Props {
  imgNode: ReactNode;
  itemNode: ReactNode;
  featureNode: ReactNode;
  disableFavorites?: boolean;
}

export const Card: FC<Props> = ({ imgNode, itemNode, featureNode, disableFavorites }) => {
  return (
    <div className='card'>
      <div className='details'>
          <div className='img_container'>{imgNode}</div>
          <div className='options'>
              {itemNode}
              {/*{!disableFavorites && (*/}
              {/*    <div className='btn_favorites'>*/}
              {/*        <img src={favorite} alt='' />*/}
              {/*        <h3 className='title'>В избранное</h3>*/}
              {/*    </div>*/}
              {/*)}*/}
          </div>
      </div>
      <div className='feature__container'>{featureNode}</div>
    </div>
  );
};
